import React from "react";
import {Route, Switch} from "react-router-dom";
import Home from "./Containers/Home";
import Login from "./Containers/Login";
import Signup from "./Containers/Signup";
import Materias from "./Containers/Materias";
import Apoyo from "./Containers/Apoyo";
import Biblioteca from "./Containers/Biblioteca";
import Imagenes from "./Containers/Imagenes";
import Simuladores from "./Containers/Simuladores";
import About from "./Containers/About";
import NotFound from "./Containers/NotFound";
import MateriasCuatrimestre from "./Containers/MateriasCuatrimestre";

export default function Routes() {
    return (
        <Switch>
            <Route exact path="/">
                <Home/>
            </Route>
            <Route exact path="/login">
                <Login/>
            </Route>
            <Route exact path="/signup">
                <Signup/>
            </Route>
            <Route exact path="/materias">
                <Materias/>
            </Route>
            <Route exact path="/materias-cuatrimestre">
                <MateriasCuatrimestre/>
            </Route>
            <Route exact path="/apoyo">
                <Apoyo/>
            </Route>
            <Route exact path="/biblioteca">
                <Biblioteca/>
            </Route>
            <Route exact path="/imagenes">
                <Imagenes/>
            </Route>
            <Route exact path="/simuladores">
                <Simuladores/>
            </Route>
            <Route exact path="/about">
                <About/>
            </Route>
            {/* Finally, catch all unmatched routes */}
            <Route>
                <NotFound/>
            </Route>
        </Switch>
    );
};