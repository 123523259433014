export const ImagesResources = [
    {
        "name": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 21.JPG",
        "url": "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/Images/Carrera+Enfermeria+Universidad+Vasconcelos+de+Tabasco+-+21.JPG",
        "alt": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 21"
    },
    {
        "name": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 20.JPG",
        "url": "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/Images/Carrera+Enfermeria+Universidad+Vasconcelos+de+Tabasco+-+20.JPG",
        "alt": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 20"
    },
    {
        "name": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 3.JPG",
        "url": "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/Images/Carrera+Enfermeria+Universidad+Vasconcelos+de+Tabasco+-+3.JPG",
        "alt": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 3"
    },
    {
        "name": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 1.JPG",
        "url": "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/Images/Carrera+Enfermeria+Universidad+Vasconcelos+de+Tabasco+-+1.JPG",
        "alt": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 1"
    },
    {
        "name": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 22.JPG",
        "url": "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/Images/Carrera+Enfermeria+Universidad+Vasconcelos+de+Tabasco+-+22.JPG",
        "alt": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 22"
    },
    {
        "name": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 23.JPG",
        "url": "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/Images/Carrera+Enfermeria+Universidad+Vasconcelos+de+Tabasco+-+23.JPG",
        "alt": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 23"
    },
    {
        "name": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 27.JPG",
        "url": "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/Images/Carrera+Enfermeria+Universidad+Vasconcelos+de+Tabasco+-+27.JPG",
        "alt": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 27"
    },
    {
        "name": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 4.JPG",
        "url": "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/Images/Carrera+Enfermeria+Universidad+Vasconcelos+de+Tabasco+-+4.JPG",
        "alt": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 4"
    },
    {
        "name": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 26.JPG",
        "url": "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/Images/Carrera+Enfermeria+Universidad+Vasconcelos+de+Tabasco+-+26.JPG",
        "alt": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 26"
    },
    {
        "name": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 5.JPG",
        "url": "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/Images/Carrera+Enfermeria+Universidad+Vasconcelos+de+Tabasco+-+5.JPG",
        "alt": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 5"
    },
    {
        "name": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 18.JPG",
        "url": "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/Images/Carrera+Enfermeria+Universidad+Vasconcelos+de+Tabasco+-+18.JPG",
        "alt": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 18"
    },
    {
        "name": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 7.JPG",
        "url": "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/Images/Carrera+Enfermeria+Universidad+Vasconcelos+de+Tabasco+-+7.JPG",
        "alt": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 7"
    },
    {
        "name": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 24.JPG",
        "url": "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/Images/Carrera+Enfermeria+Universidad+Vasconcelos+de+Tabasco+-+24.JPG",
        "alt": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 24"
    },
    {
        "name": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 25.JPG",
        "url": "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/Images/Carrera+Enfermeria+Universidad+Vasconcelos+de+Tabasco+-+25.JPG",
        "alt": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 25"
    },
    {
        "name": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 19.JPG",
        "url": "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/Images/Carrera+Enfermeria+Universidad+Vasconcelos+de+Tabasco+-+19.JPG",
        "alt": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 19"
    },
    {
        "name": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 14.JPG",
        "url": "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/Images/Carrera+Enfermeria+Universidad+Vasconcelos+de+Tabasco+-+14.JPG",
        "alt": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 14"
    },
    {
        "name": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 28.JPG",
        "url": "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/Images/Carrera+Enfermeria+Universidad+Vasconcelos+de+Tabasco+-+28.JPG",
        "alt": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 28"
    },
    {
        "name": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 15.JPG",
        "url": "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/Images/Carrera+Enfermeria+Universidad+Vasconcelos+de+Tabasco+-+15.JPG",
        "alt": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 15"
    },
    {
        "name": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 17.JPG",
        "url": "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/Images/Carrera+Enfermeria+Universidad+Vasconcelos+de+Tabasco+-+17.JPG",
        "alt": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 17"
    },
    {
        "name": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 8.JPG",
        "url": "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/Images/Carrera+Enfermeria+Universidad+Vasconcelos+de+Tabasco+-+8.JPG",
        "alt": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 8"
    },
    {
        "name": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 9.JPG",
        "url": "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/Images/Carrera+Enfermeria+Universidad+Vasconcelos+de+Tabasco+-+9.JPG",
        "alt": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 9"
    },
    {
        "name": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 16.JPG",
        "url": "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/Images/Carrera+Enfermeria+Universidad+Vasconcelos+de+Tabasco+-+16.JPG",
        "alt": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 16"
    },
    {
        "name": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 12.JPG",
        "url": "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/Images/Carrera+Enfermeria+Universidad+Vasconcelos+de+Tabasco+-+12.JPG",
        "alt": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 12"
    },
    {
        "name": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 13.JPG",
        "url": "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/Images/Carrera+Enfermeria+Universidad+Vasconcelos+de+Tabasco+-+13.JPG",
        "alt": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 13"
    },
    {
        "name": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 11.JPG",
        "url": "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/Images/Carrera+Enfermeria+Universidad+Vasconcelos+de+Tabasco+-+11.JPG",
        "alt": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 11"
    },
    {
        "name": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 10.JPG",
        "url": "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/Images/Carrera+Enfermeria+Universidad+Vasconcelos+de+Tabasco+-+10.JPG",
        "alt": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 10"
    },
    {
        "name": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 30.JPG",
        "url": "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/Images/Carrera+Enfermeria+Universidad+Vasconcelos+de+Tabasco+-+30.JPG",
        "alt": "Carrera Enfermeria Universidad Vasconcelos de Tabasco - 30"
    }
]