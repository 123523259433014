import React from "react";
import {Container, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import {SimuladorResources} from "../Source/SimuladorResources";
import {blueGrey} from '@material-ui/core/colors';
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '50px 50px',
        color: blueGrey[800]
    },
    card: {
        maxWidth: '500px'
    }
}));

export default function Simuladores(props) {
    const classes = useStyles();
    return (
        <Container className={classes.root}>
            <Typography variant="h3" gutterBottom>
                Simuladores
            </Typography>
            <div>
                <Grid container spacing={2}>
                    {SimuladorResources.map(resource => {
                        return <Grid item xs={6}>
                            <Card className={classes.card}>
                                <CardActionArea href={resource.url}>
                                    <CardMedia
                                        component="img"
                                        alt={resource.alt}
                                        height="200"
                                        width="280"
                                        image={resource.img}
                                        title={resource.title}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            {resource.title}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            {resource.description}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions>
                                    <Button size="small" color="primary" href={resource.url}>
                                        Visitar Simulador
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    })}
                </Grid>
            </div>
        </Container>
    );
}

