import React from "react";
import {Container, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    list: {
        width: '100%',
        // maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    root: {
        padding: '50px 50px'
    }
}));

export default function About(props) {
    const classes = useStyles();
    return (
        <Container className={classes.root}>
            <Typography variant="h3" gutterBottom>
                Información
            </Typography>
            <Typography variant="h6" gutterBottom>
                Contáctanos!
            </Typography>
            <Typography variant="body1" gutterBottom>

            </Typography>
        </Container>
    );
}

