export const BibliotecaSource = [
    {
        name: "MEDWAVE REVISTA MEDICA",
        link: "https://www.medwave.cl/link.cgi/descripcion.act#11",
        type: 'REVISTA'
    },
    {
        name: "REVISTA ELSEVIER",
        link: "https://www.elsevier.es/es",
        type: 'REVISTA'
    },
    {
        name: "SciELO SALUD PUBLICA",
        link: "http://scielo.isciii.es/scielo.php",
        type: 'REVISTA'
    },
    {
        name: "ReDALyC",
        link: "https://www.redalyc.org/",
        type: 'REVISTA'
    },
    {
        name: "REVISTA UNAM",
        link: "http://www.revistas.unam.mx/catalogo/index.php/revistas/busqueda_avanzada",
        type: 'REVISTA'
    },
    {
        name: "Scimago Journal & Country Rank",
        link: "https://www.scimagojr.com/index.php",
        type: 'REVISTA'
    },
    {
        name: "Biblioweb Coahuila",
        link: "",
        type: "DIGITAL"
    },
    {
        name: "Biblioteca digital Universidad Autónoma de Coahuila",
        link: "",
        type: "DIGITAL"
    },
    {
        name: "Red de Bibliotecas Digitales UNAM – TLAXCALA",
        link: "",
        type: "DIGITAL"
    },
    {
        name: "Ciencias de la salud libre, universidad veracruzana",
        link: "https://www.uv.mx/bvirtual/bases-de-datos-libre-acceso/ciencias-de-la-salud-libre/",
        type: "DIGITAL"
    },
    {
        name: "Biblioteca virtual en salud Honduras",
        link: "http://www.bvs.hn/php/level.php?lang=es&component=17&item=39",
        type: "DIGITAL"
    },
    {
        name: "Portal SciELO Argentina",
        link: "https://www.scielo.org.ar",
        type: "DIGITAL"
    },
    {
        name: "Portal SciELO regional",
        link: "www.scielo.org",
        type: "DIGITAL"
    },
    {
        name: "Portal Regional de BVS",
        link: "https://www.bireme.br",
        type: "DIGITAL"
    },
    {
        name: "BIBLIOTECA DIGITAL MUNDIAL",
        link: "https://www.wdl.org/es/",
        type: "DIGITAL"
    },
    {
        name: "SERVICIOS DE BIBLIOTECAS UNIVERSIDAD DE EXTREMADURA",
        link: "https://biblioguias.unex.es/c.php?g=572033&p=3944716",
        type: "DIGITAL"
    },
    {
        name: "Biblioteca virtual en salud Enfermería México",
        link: "https://bvsenfermeria.bvsalud.org/mexico",
        type: "DIGITAL"
    },
    {
        name: "Biblioteca virtual en salud Enfermería",
        link: "https://bvsenfermeria.bvsalud.org/",
        type: "DIGITAL"
    },
    {
        name: "Base de Datos LEGISALUD Argentina",
        link: "https://www.legisalud.gov.ar",
        type: "DIGITAL"
    },
    {
        name: "COCHRANE Library Plus",
        link: "https://www.cochranelibrary.com/es/",
        type: "DIGITAL"
    },
    {
        name: "DOAB: Directory of Open Access Books",
        link: "https://www.doabooks.org/doab?func=search&uiLanguage=en",
        type: "DIGITAL"
    },
    {
        name: "IMBIOMED",
        link: "https://imbiomed.com.mx/1/1/catalogo.html",
        type: "DIGITAL"
    },
    {
        name: "Libros UNAM Open Access",
        link: "http://www.librosoa.unam.mx/",
        type: "DIGITAL"
    },
    {
        name: "LILACS",
        link: "https://lilacs.bvsalud.org/es/",
        type: "DIGITAL"
    },
    {
        name: "REPOSITORIO INSTITUCIONAL DE LA UNAM",
        link: "https://repositorio.unam.mx/contenidos/es/",
        type: "DIGITAL"
    },
    {
        name: "REPOSITORIO NACIONAL",
        link: "https://www.repositorionacionalcti.mx/",
        type: "DIGITAL"
    },

];

