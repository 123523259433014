import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import {blueGrey} from '@material-ui/core/colors';
import VideoPlayer from 'react-simple-video-player';
import {makeStyles} from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import {Container, Typography} from "@material-ui/core";
import {ActitudesSources} from "../Source/ActitudesSources";
import CardActionArea from "@material-ui/core/CardActionArea";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '80%',
        padding: '50px 50px',
        color: blueGrey[800]
    },
    section: {
        paddingTop: '20px'
    },
    welcome: {
        padding: '35px 0px'
    },
    welcomeText: {
        letterSpacing: '4px',
        textSize: '2em'
    },
    card: {
        maxWidth: '500px'
    },
    mission: {
        // paddingTop: '0px',
        backgroundColor: '#d6e0f0',
    },
    attitudes: {
    },
    backgroundImage: {
        background: 'url(https://sim4r4-public.s3.amazonaws.com/enfermeriauvt-source/Images/BackgroundUVT.jpeg)',
        backgroundSize: '100%',
        height: '300px',
        // margin: '-100px',
        // padding: '120px',
        padding: '200px 30px',
        opacity: '.85',
        backgroundRepeat: 'no-repeat',
        color: 'white'
    },
    divSeparator: {
        height: '80px'
    },
    vision: {
        paddingTop: '35px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        borderTop: '2px solid rgb(31, 155, 207)',
        borderBottom: '2px solid rgb(31, 155, 207)',
        fontSize: '1.5em',
        paddingBottom: '50px',
    }
}));

export default function Home(props) {
    const classes = useStyles();
    return (
        <Container className={classes.root}>
            <Grid item style={{textAlign: "center", paddingBottom: '35px'}}>
                <Typography variant="h2" gutterBottom>
                    Universidad Vasconcelos de Tabasco
                </Typography>
                <span>
                    <img width='50%' src={require('../Source/LogoEnfermeria.jpeg')} alt='logo enfermeria uvt'/>
                </span>
                <Typography variant="h3" style={{padding: '35px 0'}}>
                    Licenciatura en Enfermería
                </Typography>
            </Grid>
            <div style={{
                backgroundColor: '#f56a79',
                paddingTop: '52px',
                color: blueGrey[50],
                margin: '0 -60px',
                height: '105px'
            }}>
                <Typography style={{margin: 'auto', textAlign: 'center'}} variant='h4'>
                    Mensaje de Bienvenida
                </Typography>
            </div>
            <div className={classes.welcome}>
                <Grid container spacing={3} justify='center' alignItems="center">
                    <Grid item xs={12} sm={5}>
                        <VideoPlayer
                            width={250}
                            aspectRatio='3:3'
                            url="https://sim4r4-public.s3.amazonaws.com/enfermeriauvt-source/Videos/video-bienvenida.MP4"
                            poster="https://sim4r4-public.s3.amazonaws.com/enfermeriauvt-source/Images/Video+Bienvenida+Enfermeria.jpg"
                        />
                    </Grid>
                    <Grid item xs={12} sm={7} className={classes.welcomeText}>
                        <Typography>
                            "El inicio de un nuevo ciclo escolar es siempre una feliz ocasión para dar una formal
                            bienvenida a alumnos y docentes de las distintas carreras de la Universidad Vasconcelos de
                            Tabasco.
                            Como ya es costumbre, este año los recibimos con mucha alegría y entusiasmo."
                        </Typography>

                    </Grid>
                </Grid>
            </div>
            <div className={classes.divSeparator}> </div>
            <div className={classes.backgroundImage}>
                <Typography style={{fontSize: '1.75em'}}>Misión</Typography>
                <Typography style={{fontSize: '1.5em'}}>
                    Estar a la vanguardia en la formación de profesionales de enfermería competentes para proporcionar
                    cuidados de calidad en base a las necesidades de salud de la persona, familia y comunidad a nivel
                    local, regional y nacional; caracterizada por su actuar profesional y científico en equipos
                    multidisciplinarios.
                </Typography>
            </div>
            <div>
                <blockquote className={classes.vision}>
                    <p className="mb-0 text-primary">Formar profesionales de enfermería con calidad académica, valores éticos y humanísticos para dar
                        respuesta a las demandas y retos profesionales en el área asistencial, gerencial, educativa y de
                        Investigación.</p>
                    <footer className="blockquote-footer mt-2">
                        <cite title="Visión UVT">- Visión UVT</cite>
                    </footer>
                </blockquote>
            </div>
            <div>
                <Typography variant='h3' style={{padding: '50px 0px'}}>Actitudes y Valores</Typography>
                <Grid container spacing={2}>
                    {ActitudesSources.map(resource => {
                        return <Grid item xs={6}>
                            <Card className={classes.card}>
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        alt={resource.alt}
                                        height="200"
                                        width="280"
                                        image={resource.url}
                                        title={resource.title}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            {resource.title}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            {resource.description}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    })}
                </Grid>
            </div>
        </Container>
    );
}
