export const MateriasDownloadable = [
    {
        semester: "1ER SEMESTRE",
        materias: [
            {
                nombre: "PROCESO ENFERMERO",
                recursos: [
                    {
                        nombre: "Diagnosticos de Enfermeria NANDA 2018_2020.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/PROCESO+ENFERMERO/Diagno%CC%81sticos+de+Enfermeria+NANDA+2018_2020.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Algoritmos NNN edicion 2017 CD Erik Hernandez (1).pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/PROCESO+ENFERMERO/Algoritmos+NNN+edicio%CC%81n+2017+CD+Erik+Herna%CC%81ndez+(1).pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Clasificacion de resultados NOC.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/PROCESO+ENFERMERO/Clasificacion+de+resultados+NOC.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "NANDA NIC NOC Modelos de cuidados .pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/PROCESO+ENFERMERO/NANDA+NIC+NOC+Modelos+de+cuidados+.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Clasificacion de intervenciones NIC.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/PROCESO+ENFERMERO/Clasificacion+de+intervenciones+NIC.pdf",
                        tipo: "LIBRO"
                    }
                ]
            },
            {
                nombre: "MICROBIOLOGIA Y PARASITOLOGIA",
                recursos: [
                    {
                        nombre: "Copia de Microbiologia y Parasitologia Humana Romero Cabello.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/MICROBIOLOGIA+Y+PARASITOLOGIA/Copia+de+Microbiologi%CC%81a+y+Parasitologi%CC%81a+Humana+Romero+Cabello.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Microbiologia en Ciencias de la Salud - Conceptos y Aplicaciones 3ed De La Rosa-Prieto-Navarro.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/MICROBIOLOGIA+Y+PARASITOLOGIA/Microbiologia+en+Ciencias+de+la+Salud+-+Conceptos+y+Aplicaciones+3ed+De+La+Rosa-Prieto-Navarro.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Manual-de-Microbiologia-y-Parasitologia-2013.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/MICROBIOLOGIA+Y+PARASITOLOGIA/Manual-de-Microbiologia-y-Parasitologia-2013.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Documento_completo.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/MICROBIOLOGIA+Y+PARASITOLOGIA/Documento_completo.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "MICROBIOLOGIA-Y-SALUD.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/MICROBIOLOGIA+Y+PARASITOLOGIA/MICROBIOLOGI%CC%81A-Y-SALUD.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Manual.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/MICROBIOLOGIA+Y+PARASITOLOGIA/Manual.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "microbiologia-general.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/MICROBIOLOGIA+Y+PARASITOLOGIA/microbiologia-general.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "MICROBIOLOGIA MEDICA-SHERRIS.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/MICROBIOLOGIA+Y+PARASITOLOGIA/MICROBIOLOGIA+MEDICA-SHERRIS.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Tipos de microorganismos.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/MICROBIOLOGIA+Y+PARASITOLOGIA/Tipos+de+microorganismos.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Introduccion a la Parasitologia II.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/MICROBIOLOGIA+Y+PARASITOLOGIA/Introduccio%CC%81n+a+la+Parasitologi%CC%81a+II.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Introduccion a la Parasitologia I.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/MICROBIOLOGIA+Y+PARASITOLOGIA/Introduccio%CC%81n+a+la+Parasitologi%CC%81a+I.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Generalidades de los virus.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/MICROBIOLOGIA+Y+PARASITOLOGIA/Generalidades+de+los+virus.mp4",
                        tipo: "VIDEO"
                    }
                ]
            },
            {
                nombre: "ANATOMIA Y FISIOLOGIA",
                recursos: [
                    {
                        nombre: "29 ANATOMIA Y FISIOLOGIA DEL SISTEMA NERVIOSO.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/ANATOMIA+Y+FISIOLOGIA/29+ANATOMIA+Y+FISIOLOGIA+DEL+SISTEMA+NERVIOSO.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "ANATOMIA NETTER.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/ANATOMIA+Y+FISIOLOGIA/ANATOMIA+NETTER.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Tratado de fisiologia medica_Guyton y Hall_12da Ed.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/ANATOMIA+Y+FISIOLOGIA/Tratado+de+fisiologia+medica_Guyton+y+Hall_12da+Ed.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "anatomia y fisiologia maried.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/ANATOMIA+Y+FISIOLOGIA/anatomia+y+fisiologia+maried.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "2 NETTER ANATOMIA HUMANA ATLAS.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/ANATOMIA+Y+FISIOLOGIA/2+NETTER+ANATOMI%CC%81A+HUMANA+ATLAS.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Anatomia y Fisiologia para Enfermeras.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/ANATOMIA+Y+FISIOLOGIA/Anatomia+y+Fisiologia+para+Enfermeras.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "ENDOCRINOLOGIA.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/ANATOMIA+Y+FISIOLOGIA/ENDOCRINOLOGIA.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Fisiologia Humana - Stuart Ira Fox 12ed.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/ANATOMIA+Y+FISIOLOGIA/Fisiologi%CC%81a+Humana+-+Stuart+Ira+Fox+12ed.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "FISIOLOGIA MEDICA GYUTO.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/ANATOMIA+Y+FISIOLOGIA/FISIOLOGIA+MEDICA+GYUTO.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "ANATOMIA HUMANA HETTER.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/ANATOMIA+Y+FISIOLOGIA/ANATOMIA+HUMANA+HETTER.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Fisiologia-Humana-Aplicada-a-Las-Ciencias-de-La-Salud Reyes Toso.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/ANATOMIA+Y+FISIOLOGIA/Fisiologia-Humana-Aplicada-a-Las-Ciencias-de-La-Salud+Reyes+Toso.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Sistema Muscular.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/ANATOMIA+Y+FISIOLOGIA/Sistema+Muscular.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Corazon, Anatomia y Fisiologia.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/ANATOMIA+Y+FISIOLOGIA/Corazo%CC%81n,+Anatomi%CC%81a+y+Fisiologi%CC%81a.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Introduccion a la anatomia .mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/ANATOMIA+Y+FISIOLOGIA/Introduccio%CC%81n+a+la+anatomi%CC%81a+.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Cancion de los musculos del cuerpo humano.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/ANATOMIA+Y+FISIOLOGIA/Cancio%CC%81n+de+los+mu%CC%81sculos+del+cuerpo+humano.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Planos anatomicos.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/ANATOMIA+Y+FISIOLOGIA/Planos+anatomicos.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Musculos.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/ANATOMIA+Y+FISIOLOGIA/Musculos.mp4",
                        tipo: "VIDEO"
                    }
                ]
            },
            {
                nombre: "COMUNICACION Y EDUCACION PARA LA SALUD",
                recursos: [
                    {
                        nombre: "educacion para la salud.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/COMUNICACION+Y+EDUCACION+PARA+LA+SALUD/educacion+para+la+salud.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Comunicacion de la salud.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/COMUNICACION+Y+EDUCACION+PARA+LA+SALUD/Comunicacion+de+la+salud.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Promocion de la salud.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/COMUNICACION+Y+EDUCACION+PARA+LA+SALUD/Promocion+de+la+salud.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Comunicacion para la salud extramadura.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/COMUNICACION+Y+EDUCACION+PARA+LA+SALUD/Comunicacion+para+la+salud+extramadura.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "3348-3699-1-PB (1).pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/COMUNICACION+Y+EDUCACION+PARA+LA+SALUD/3348-3699-1-PB+(1).pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Educacion para la salud basada en habilidades.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/COMUNICACION+Y+EDUCACION+PARA+LA+SALUD/Educacio%CC%81n+para+la+salud+basada+en+habilidades.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "EDUCACION PARA LA SALUD.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/COMUNICACION+Y+EDUCACION+PARA+LA+SALUD/EDUCACION+PARA+LA+SALUD.mp4",
                        tipo: "VIDEO"
                    }
                ]
            },
            {
                nombre: "SALUD PUBLICA Y EPIDEMIOLOGIA",
                recursos: [
                    {
                        nombre: "Documento_completo__.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/SALUD+PUBLICA+Y+EPIDEMIOLOGIA/Documento_completo__.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Copia de Epidemiologia Clinica. Fletcher 5a Edicion.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/SALUD+PUBLICA+Y+EPIDEMIOLOGIA/Copia+de+Epidemiologi%CC%81a+Cli%CC%81nica.+Fletcher+5a+Edicion.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Salud Publica y Enfermeria Comunitaria.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/SALUD+PUBLICA+Y+EPIDEMIOLOGIA/Salud+Publica+y+Enfermeria+Comunitaria.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Vacunas 1a Edicion.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/SALUD+PUBLICA+Y+EPIDEMIOLOGIA/Vacunas+1a+Edicion.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "AIEPI COMUNITARIO.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/SALUD+PUBLICA+Y+EPIDEMIOLOGIA/AIEPI+COMUNITARIO.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "05 Salud publica-Interiores.PDF",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/SALUD+PUBLICA+Y+EPIDEMIOLOGIA/05+Salud+pu%CC%81blica-Interiores.PDF",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "AIEPI.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/SALUD+PUBLICA+Y+EPIDEMIOLOGIA/AIEPI.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "MODELO DE ATENCION DE ENFERMERIA COMUNITARIA.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/SALUD+PUBLICA+Y+EPIDEMIOLOGIA/MODELO+DE+ATENCION+DE+ENFERMERIA+COMUNITARIA.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "025 Salud Publica 1.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/SALUD+PUBLICA+Y+EPIDEMIOLOGIA/025+Salud+Publica+1.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Salud publica.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/SALUD+PUBLICA+Y+EPIDEMIOLOGIA/Salud+publica.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "La epidemiologia.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/SALUD+PUBLICA+Y+EPIDEMIOLOGIA/La+epidemiologia.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Termino salud publica.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/SALUD+PUBLICA+Y+EPIDEMIOLOGIA/Termino+salud+publica.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Epidemiologia.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/SALUD+PUBLICA+Y+EPIDEMIOLOGIA/Epidemiologia.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Principios de salud publica.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/SALUD+PUBLICA+Y+EPIDEMIOLOGIA/Principios+de+salud+publica.mp4",
                        tipo: "VIDEO"
                    }
                ]
            },
            {
                nombre: "ENFERMERIA COMUNITARIA",
                recursos: [
                    {
                        nombre: "Enfermeria en salud comunitaria un enfoque de sistemas.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/ENFERMERIA+COMUNITARIA/Enfermeria+en+salud+comunitaria+un+enfoque+de+sistemas.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Salud Publica y Enfermeria Comunitaria.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/ENFERMERIA+COMUNITARIA/Salud+Publica+y+Enfermeria+Comunitaria.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Salud publica y enfermeria comunitaria, ed. 1 - Maria Susana Gonzalez Vazquez. 2018. Manual Moderno Mexico.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/ENFERMERIA+COMUNITARIA/Salud+pu%CC%81blica+y+enfermeri%CC%81a+comunitaria,+ed.+1+-+Mari%CC%81a+Susana+Gonza%CC%81lez+Va%CC%81zquez.+2018.+Manual+Moderno+Me%CC%81xico.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Copia de Enfermeria Comunitaria - Frias Osuna.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/ENFERMERIA+COMUNITARIA/Copia+de+Enfermeria+Comunitaria+-+Frias+Osuna.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "ROL Y FUNCIONES DE LA ENFERMERIA FAMILIAR Y COMUNITARIA.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/ENFERMERIA+COMUNITARIA/ROL+Y+FUNCIONES+DE+LA+ENFERMERIA+FAMILIAR+Y+COMUNITARIA.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Atencion Primaria de la Salud.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/ENFERMERIA+COMUNITARIA/Atencio%CC%81n+Primaria+de+la+Salud.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Quien es la enfermera comunitaria UA.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/ENFERMERIA+COMUNITARIA/%C2%BFQuie%CC%81n+es+la+enfermera+comunitaria+UA.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Que es Salud Publica.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/ENFERMERIA+COMUNITARIA/Que%CC%81+es+Salud+Pu%CC%81blica.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Se Comunicarme en Salud (Comunicacion y Promocion para la Salud).mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/1ER+SEMESTRE/ENFERMERIA+COMUNITARIA/Se+Comunicarme+en+Salud+(Comunicacio%CC%81n+y+Promocio%CC%81n+para+la+Salud).mp4",
                        tipo: "VIDEO"
                    }
                ]
            }
        ]
    },
    {
        semester: "2DO SEMESTRE",
        materias: [
            {
                nombre: "FARMACOLOGIA EN ENFERMERIA ",
                recursos: [
                    {
                        nombre: "ADMINISTRACION DE MEDICAMENTOS Y CALCULO DE DOSIS-ZABALEGUI.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/FARMACOLOGIA+EN+ENFERMERIA+/ADMINISTRACION+DE+MEDICAMENTOS+Y+CALCULO+DE+DOSIS-ZABALEGUI.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Farmacologia General (1).pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/FARMACOLOGIA+EN+ENFERMERIA+/Farmacologia+General+(1).pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "netter_farmacologia_ilustrada.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/FARMACOLOGIA+EN+ENFERMERIA+/netter_farmacologia_ilustrada.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "DOC-20160810-WA0013.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/FARMACOLOGIA+EN+ENFERMERIA+/DOC-20160810-WA0013.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "CUADRO BASICO EDICION_2017_MEDICAMENTOS-FINAL.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/FARMACOLOGIA+EN+ENFERMERIA+/CUADRO+BASICO+EDICION_2017_MEDICAMENTOS-FINAL.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Hernandez Chavez, A. Farmacologia General. Una guia de estudio. 2014.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/FARMACOLOGIA+EN+ENFERMERIA+/Herna%CC%81ndez+Cha%CC%81vez,+A.+Farmacologi%CC%81a+General.+Una+gui%CC%81a+de+estudio.+2014.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Farmacologia en Enfermeria CASTELLS 3ed medilibros.com.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/FARMACOLOGIA+EN+ENFERMERIA+/Farmacologia+en+Enfermeria+CASTELLS+3ed+medilibros.com.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Manual de Medicamentos Endovenosos_booksmedicos.org.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/FARMACOLOGIA+EN+ENFERMERIA+/Manual+de+Medicamentos+Endovenosos_booksmedicos.org.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Farmacologia_Clinica_para_enfermeria.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/FARMACOLOGIA+EN+ENFERMERIA+/Farmacologia_Clinica_para_enfermeria.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Farmacologia como ciencia.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/FARMACOLOGIA+EN+ENFERMERIA+/Farmacologi%CC%81a+como+ciencia.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Como calcular dosis de Medicamentos - Facil.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/FARMACOLOGIA+EN+ENFERMERIA+/Co%CC%81mo+calcular+dosis+de+Medicamentos+-+Fa%CC%81cil.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Farmacologia .mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/FARMACOLOGIA+EN+ENFERMERIA+/Farmacologi%CC%81a+.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Farmacologia para enfermeria .mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/FARMACOLOGIA+EN+ENFERMERIA+/Farmacologi%CC%81a+para+enfermeri%CC%81a+.mp4",
                        tipo: "VIDEO"
                    }
                ]
            },
            {
                nombre: "MODELOS Y TEORIAS DE ENFERMERIA ",
                recursos: [
                    {
                        nombre: "IntroduccionALasTeoriasYModelosDeEnfermeria.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/MODELOS+Y+TEORIAS+DE+ENFERMERIA+/IntroduccionALasTeoriasYModelosDeEnfermeria.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Teoria y modelos de Enfermeria de ann mariner.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/MODELOS+Y+TEORIAS+DE+ENFERMERIA+/Teoria+y+modelos+de+Enfermeria+de+ann+mariner.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "HISTORIA DE LA ENFERMERIA.SILES.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/MODELOS+Y+TEORIAS+DE+ENFERMERIA+/HISTORIA+DE+LA+ENFERMERIA.SILES.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Teoria del autocuidado.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/MODELOS+Y+TEORIAS+DE+ENFERMERIA+/Teori%CC%81a+del+autocuidado.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Teorias de enfermeria .mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/MODELOS+Y+TEORIAS+DE+ENFERMERIA+/Teori%CC%81as+de+enfermeri%CC%81a+.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Betty Neuman .mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/MODELOS+Y+TEORIAS+DE+ENFERMERIA+/Betty+Neuman+.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "historia de la enfermeria.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/MODELOS+Y+TEORIAS+DE+ENFERMERIA+/historia+de+la+enfermeria.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Modelo de adaptacion .mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/MODELOS+Y+TEORIAS+DE+ENFERMERIA+/Modelo+de+adaptacio%CC%81n+.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "virginia henderson 14 necesidades (2).mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/MODELOS+Y+TEORIAS+DE+ENFERMERIA+/virginia+henderson+14+necesidades+(2).mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Modelos y teorias de enfermeria.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/MODELOS+Y+TEORIAS+DE+ENFERMERIA+/Modelos+y+teori%CC%81as+de+enfermeria.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Modelos y teorias .mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/MODELOS+Y+TEORIAS+DE+ENFERMERIA+/Modelos+y+teori%CC%81as+.mp4",
                        tipo: "VIDEO"
                    }
                ]
            },
            {
                nombre: "ETICA, BIOETICA Y LEGISLACION EN ENFERMERIA",
                recursos: [
                    {
                        nombre: "LEY_GENERAL_DE_SALUD (1).pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/ETICA,+BIOETICA+Y+LEGISLACION+EN+ENFERMERIA/LEY_GENERAL_DE_SALUD+(1).pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "codigo_etica (1).pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/ETICA,+BIOETICA+Y+LEGISLACION+EN+ENFERMERIA/codigo_etica+(1).pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "declaracion universal de los derechos humanos. pdf.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/ETICA,+BIOETICA+Y+LEGISLACION+EN+ENFERMERIA/declaracion+universal+de+los+derechos+humanos.+pdf.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "codigo_penal (1).pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/ETICA,+BIOETICA+Y+LEGISLACION+EN+ENFERMERIA/codigo_penal+(1).pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "BIOETICA PARA ESTUDIANTES Y PROFESIONALES DE CIENCIAS DE LA SALUD.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/ETICA,+BIOETICA+Y+LEGISLACION+EN+ENFERMERIA/BIOETICA+PARA+ESTUDIANTES+Y+PROFESIONALES+DE+CIENCIAS+DE+LA+SALUD.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "CONAMET.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/ETICA,+BIOETICA+Y+LEGISLACION+EN+ENFERMERIA/CONAMET.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "legislacionenfermeria-120217224509-phpapp01.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/ETICA,+BIOETICA+Y+LEGISLACION+EN+ENFERMERIA/legislacionenfermeria-120217224509-phpapp01.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "codigo_civil_estado_tabasco (2).pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/ETICA,+BIOETICA+Y+LEGISLACION+EN+ENFERMERIA/codigo_civil_estado_tabasco+(2).pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "DERECHOS HUMANOS.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/ETICA,+BIOETICA+Y+LEGISLACION+EN+ENFERMERIA/DERECHOS+HUMANOS.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Comprendiendo_el_derecho_humano_a_la_salud (2).pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/ETICA,+BIOETICA+Y+LEGISLACION+EN+ENFERMERIA/Comprendiendo_el_derecho_humano_a_la_salud+(2).pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "principios de bioetica.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/ETICA,+BIOETICA+Y+LEGISLACION+EN+ENFERMERIA/principios+de+bioetica.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Etica y enfermeria .mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/ETICA,+BIOETICA+Y+LEGISLACION+EN+ENFERMERIA/E%CC%81tica+y+enfermeri%CC%81a+.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Bioetica y legislacion.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/ETICA,+BIOETICA+Y+LEGISLACION+EN+ENFERMERIA/Bioe%CC%81tica+y+legislacion.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Bioetica y Legislacion .mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/ETICA,+BIOETICA+Y+LEGISLACION+EN+ENFERMERIA/Bioe%CC%81tica+y+Legislacio%CC%81n+.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "La etica en enfermeria .mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/ETICA,+BIOETICA+Y+LEGISLACION+EN+ENFERMERIA/La+e%CC%81tica+en+enfermeri%CC%81a+.mp4",
                        tipo: "VIDEO"
                    }
                ]
            },
            {
                nombre: "BASES ELEMENTLES DE BIOQUIMICA",
                recursos: [
                    {
                        nombre: "Bioquimica Conceptos esenciales.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/BASES+ELEMENTLES+DE+BIOQUIMICA/Bioquimica+Conceptos+esenciales.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Bioquimica.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/BASES+ELEMENTLES+DE+BIOQUIMICA/Bioquimica.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "BioquimicaYBiologiaMolecularParaCienciasDeLaSalud.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/BASES+ELEMENTLES+DE+BIOQUIMICA/BioquimicaYBiologiaMolecularParaCienciasDeLaSalud.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Bioquimica Ilustrada Harper 29 ed - Lange.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/BASES+ELEMENTLES+DE+BIOQUIMICA/Bioquimica+Ilustrada+Harper+29+ed+-+Lange.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "LIBRO-BIOQUIMICA.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/BASES+ELEMENTLES+DE+BIOQUIMICA/LIBRO-BIOQUIMICA.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Principios de la bioquimica.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/BASES+ELEMENTLES+DE+BIOQUIMICA/Principios+de+la+bioquimica.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Que es la bioquimica Cual es su objeto de estudio Biomoleculas.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/BASES+ELEMENTLES+DE+BIOQUIMICA/%C2%BFQue%CC%81+es+la+bioqui%CC%81mica+%C2%BFCua%CC%81l+es+su+objeto+de+estudio+Biomole%CC%81culas.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "BIOQUIMICA.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/BASES+ELEMENTLES+DE+BIOQUIMICA/BIOQUIMICA.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "La bioquimica.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/BASES+ELEMENTLES+DE+BIOQUIMICA/La+bioquimica.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Bioquimica(1).mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/BASES+ELEMENTLES+DE+BIOQUIMICA/Bioquimica(1).mp4",
                        tipo: "VIDEO"
                    }
                ]
            },
            {
                nombre: "ENFERMERIA FUNDAMENTAL",
                recursos: [
                    {
                        nombre: "FUNDAMENTOS DE ENFERMERIA SEG EDICION EVA REYES (2).pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/ENFERMERIA+FUNDAMENTAL/FUNDAMENTOS+DE+ENFERMERIA+SEG+EDICION+EVA+REYES+(2).pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "EXAMEN FISICO GUIA.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/ENFERMERIA+FUNDAMENTAL/EXA%CC%81MEN+FI%CC%81SICO+GUI%CC%81A.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "PAE_S URGENCIAS.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/ENFERMERIA+FUNDAMENTAL/PAE_S+URGENCIAS.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "REHABILITACION RESPIRATORIA EPOC MANUAL.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/ENFERMERIA+FUNDAMENTAL/REHABILITACIO%CC%81N+RESPIRATORIA+EPOC+MANUAL.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "7 MOSBY SEIDEL EXPLORACION FISICA.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/ENFERMERIA+FUNDAMENTAL/7+MOSBY+SEIDEL+EXPLORACIO%CC%81N+FI%CC%81SICA.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Bello Nilda - Fundamentos De Enfermeria.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/ENFERMERIA+FUNDAMENTAL/Bello+Nilda+-+Fundamentos+De+Enfermeria.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Lavado de manos quirurgico.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/ENFERMERIA+FUNDAMENTAL/Lavado+de+manos+quirurgico.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Sondaje vesical.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/ENFERMERIA+FUNDAMENTAL/Sondaje+vesical.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Tecnica de Colocacion de Sonda Nasogastrica.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/ENFERMERIA+FUNDAMENTAL/Te%CC%81cnica+de+Colocacio%CC%81n+de+Sonda+Nasoga%CC%81strica.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Colocacion  de sonda vesical (1).mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/ENFERMERIA+FUNDAMENTAL/Colocacio%CC%81n++de+sonda+vesical+(1).mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Venopuncion y venoclisis..mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/ENFERMERIA+FUNDAMENTAL/Venopuncio%CC%81n+y+venoclisis..mp4",
                        tipo: "VIDEO"
                    }
                ]
            },
            {
                nombre: "FISIOPATOLOGIA",
                recursos: [
                    {
                        nombre: "Fisiopatologia renal fundamentos - Rennke.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/FISIOPATOLOGIA/Fisiopatologia+renal+fundamentos+-+Rennke.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Fisiopatologia. Manual de mapas conceptuales.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/FISIOPATOLOGIA/Fisiopatologi%CC%81a.+Manual+de+mapas+conceptuales.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Fisiopatologia Cardiovascular Gelpi.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/FISIOPATOLOGIA/Fisiopatologia+Cardiovascular+Gelpi.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "221015Fisiopatologia.y.patologia.general.basicas.para.ciencias.de.la.salud.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/FISIOPATOLOGIA/221015Fisiopatologia.y.patologia.general.basicas.para.ciencias.de.la.salud.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Introduccion a la fisiopatologia .mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/FISIOPATOLOGIA/Introduccio%CC%81n+a+la+fisiopatologi%CC%81a+.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "FISIOPATOLOGIA- LESION Y MUERTE CELULAR I.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/FISIOPATOLOGIA/FISIOPATOLOGI%CC%81A-+LESIO%CC%81N+Y+MUERTE+CELULAR+I.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "FISIOPATOLOGIA .mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/FISIOPATOLOGIA/FISIOPATOLOGIA+.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "FISIOPATOLOGIA- INTRODUCCION.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/2DO+SEMESTRE/FISIOPATOLOGIA/FISIOPATOLOGI%CC%81A-+INTRODUCCIO%CC%81N.mp4",
                        tipo: "VIDEO"
                    }
                ]
            }
        ]
    },
    {
        semester: "3ER SEMESTRE",
        materias: [
            {
                nombre: "ENFERMERIA MATERNO-PERINATAL",
                recursos: [
                    {
                        nombre: "Asistencia.de.Enfermeria.Materno.Neonatal.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/ENFERMERIA+MATERNO-PERINATAL/Asistencia.de.Enfermeria.Materno.Neonatal.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Enfermeria Maternal y RN.pdf-comments.html",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/ENFERMERIA+MATERNO-PERINATAL/Enfermeria+Maternal+y+RN.pdf-comments.html",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Manual-Obstetricia-Ginecologia-2017.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/ENFERMERIA+MATERNO-PERINATAL/Manual-Obstetricia-Ginecologia-2017.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "guia-atencion-parto-normal.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/ENFERMERIA+MATERNO-PERINATAL/guia-atencion-parto-normal.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Triage obstetrico.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/ENFERMERIA+MATERNO-PERINATAL/Triage+obstetrico.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "MANEJO DE LAS COMPLICACIONES DEL EMBARAZO Y EL PARTO.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/ENFERMERIA+MATERNO-PERINATAL/MANEJO+DE+LAS+COMPLICACIONES+DEL+EMBARAZO+Y+EL+PARTO.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Manual_obstetricia_g.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/ENFERMERIA+MATERNO-PERINATAL/Manual_obstetricia_g.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Williams_Obstetricia_Edicion_25_Espanol.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/ENFERMERIA+MATERNO-PERINATAL/Williams_Obstetricia_Edicion_25_Espan%CC%83ol.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Manual-Obstetricia-y-Ginecologia-2018.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/ENFERMERIA+MATERNO-PERINATAL/Manual-Obstetricia-y-Ginecologi%CC%81a-2018.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Enfermeria Maternal y RN.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/ENFERMERIA+MATERNO-PERINATAL/Enfermeria+Maternal+y+RN.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "wg2da.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/ENFERMERIA+MATERNO-PERINATAL/wg2da.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Materiales y equipamiento para la Recepcion del Recien Nacido.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/ENFERMERIA+MATERNO-PERINATAL/Materiales+y+equipamiento+para+la+Recepcio%CC%81n+del+Recie%CC%81n+Nacido.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "7 Alimentos PROHIBIDOS en el embarazo.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/ENFERMERIA+MATERNO-PERINATAL/7+Alimentos+PROHIBIDOS+en+el+embarazo.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Lactancia materna exclusiva.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/ENFERMERIA+MATERNO-PERINATAL/Lactancia+materna+exclusiva.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Beneficios de la leche materna.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/ENFERMERIA+MATERNO-PERINATAL/Beneficios+de+la+leche+materna.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Tamiz Metabolico Neonatal.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/ENFERMERIA+MATERNO-PERINATAL/Tamiz+Metabo%CC%81lico+Neonatal.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Copia de 2.- Anticonceptivos Hormonales (3).mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/ENFERMERIA+MATERNO-PERINATAL/Copia+de+2.-+Anticonceptivos+Hormonales+(3).mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Que es el tamiz del recien nacido.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/ENFERMERIA+MATERNO-PERINATAL/%C2%BFQue%CC%81+es+el+tamiz+del+recie%CC%81n+nacido.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Materiales y equipamiento para la Recepcion del Recien Nacido(1).mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/ENFERMERIA+MATERNO-PERINATAL/Materiales+y+equipamiento+para+la+Recepcio%CC%81n+del+Recie%CC%81n+Nacido(1).mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Copia de 1 ANATOMIA DE LOS ORGANOS GENITALES FEMENINOS (FISIOLOGIA DEL SISTEMA REPRODUCTOR).mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/ENFERMERIA+MATERNO-PERINATAL/Copia+de+1+ANATOMI%CC%81A+DE+LOS+O%CC%81RGANOS+GENITALES+FEMENINOS+(FISIOLOGI%CC%81A+DEL+SISTEMA+REPRODUCTOR).mp4",
                        tipo: "VIDEO"
                    }
                ]
            },
            {
                nombre: "INGLES 1",
                recursos: [
                    {
                        nombre: "1_Augusto_Ghio_Ingles_basico.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/INGLES+1/1_Augusto_Ghio_Ingles_basico.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Modulo Ingles.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/INGLES+1/Modulo+Ingles.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Adverbios de Frecuencia en Ingles.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/INGLES+1/Adverbios+de+Frecuencia+en+Ingle%CC%81s.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Reglas del plural en ingles - Cuando agregar S, ES, IES, VES a los sustantivos - Plural Nouns.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/INGLES+1/Reglas+del+plural+en+ingle%CC%81s+-+Cuando+agregar+S,+ES,+IES,+VES+a+los+sustantivos+-+Plural+Nouns.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "La forma mas facil de aprender Ser o Estar - VERB TO BE.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/INGLES+1/La+forma+ma%CC%81s+fa%CC%81cil+de+aprender+Ser+o+Estar+-+VERB+TO+BE.mp4",
                        tipo: "VIDEO"
                    }
                ]
            },
            {
                nombre: "CRECIMIENTO Y DESARROLLO",
                recursos: [
                    {
                        nombre: "5manual-de-crecimiento-y-desarrollo-del-ninio1.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/CRECIMIENTO+Y+DESARROLLO/5manual-de-crecimiento-y-desarrollo-del-ninio1.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Psicologia del Desarrollo_Humano.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/CRECIMIENTO+Y+DESARROLLO/Psicologia+del+Desarrollo_Humano.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Pediatria.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/CRECIMIENTO+Y+DESARROLLO/Pediatria.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "libro-desarrollo-humano-papalia.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/CRECIMIENTO+Y+DESARROLLO/libro-desarrollo-humano-papalia.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "papalia-feldman-desarrollo-humano-12a-ed2.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/CRECIMIENTO+Y+DESARROLLO/papalia-feldman-desarrollo-humano-12a-ed2.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Farmacologia.en.Pediatria.para.Enfermeras.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/CRECIMIENTO+Y+DESARROLLO/Farmacologia.en.Pediatria.para.Enfermeras.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "TABLAS EN PEDIATRIA.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/CRECIMIENTO+Y+DESARROLLO/TABLAS+EN+PEDIATRI%CC%81A.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "ORIGEN FETAL DE LAS ENFERMEDADES DEL ADULTO.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/CRECIMIENTO+Y+DESARROLLO/ORIGEN+FETAL+DE+LAS+ENFERMEDADES+DEL+ADULTO.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "RE_2016_1_PP_1.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/CRECIMIENTO+Y+DESARROLLO/RE_2016_1_PP_1.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Periodo Embrionario y Periodo Fetal - Biologia - Desarrollo Embrionario - V4 - Egg Educacion.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/CRECIMIENTO+Y+DESARROLLO/Peri%CC%81odo+Embrionario+y+Peri%CC%81odo+Fetal+-+Biologi%CC%81a+-+Desarrollo+Embrionario+-+V4+-+Egg+Educacio%CC%81n.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Semana 3 - Biologia - Desarrollo Embrionario - V3 - Egg Educacion.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/CRECIMIENTO+Y+DESARROLLO/Semana+3+-+Biologi%CC%81a+-+Desarrollo+Embrionario+-+V3+-+Egg+Educacio%CC%81n.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "ETAPAS DEL DESARROLLO HUMANO.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/CRECIMIENTO+Y+DESARROLLO/ETAPAS+DEL+DESARROLLO+HUMANO.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Semana 2 - Biologia - Desarrollo Embrionario - V2 - Egg Educacion.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/CRECIMIENTO+Y+DESARROLLO/Semana+2+-+Biologi%CC%81a+-+Desarrollo+Embrionario+-+V2+-+Egg+Educacio%CC%81n.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Semana 1 - Biologia - Desarrollo Embrionario - V1 - Egg Educacion.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/CRECIMIENTO+Y+DESARROLLO/Semana+1+-+Biologi%CC%81a+-+Desarrollo+Embrionario+-+V1+-+Egg+Educacio%CC%81n.mp4",
                        tipo: "VIDEO"
                    }
                ]
            },
            {
                nombre: "FILOSOFIA Y ENFERMERIA",
                recursos: [
                    {
                        nombre: "CUIDADO, FILOSOFIA Y ENFERMERIA.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/FILOSOFIA+Y+ENFERMERIA/CUIDADO,+FILOSOFIA+Y+ENFERMERIA.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Filosofia de la ciencia del cuidado.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/FILOSOFIA+Y+ENFERMERIA/Filosofia+de+la+ciencia+del+cuidado.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Filosofia_de_Enfermeria.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/FILOSOFIA+Y+ENFERMERIA/Filosofia_de_Enfermeria.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "El Origen de la Filosofia(1).mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/FILOSOFIA+Y+ENFERMERIA/El+Origen+de+la+Filosofi%CC%81a(1).mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Que es la Filosofia (Segunda Parte).mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/FILOSOFIA+Y+ENFERMERIA/%C2%BFQue%CC%81+es+la+Filosofi%CC%81a+(Segunda+Parte).mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "El Origen de la Filosofia.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/FILOSOFIA+Y+ENFERMERIA/El+Origen+de+la+Filosofi%CC%81a.mp4",
                        tipo: "VIDEO"
                    }
                ]
            },
            {
                nombre: "GINECOLOGIA Y OBSTETRICIA",
                recursos: [
                    {
                        nombre: "Manual-Obstetricia-Ginecologia-2017.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/GINECOLOGIA+Y+OBSTETRICIA/Manual-Obstetricia-Ginecologia-2017.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "guia-atencion-parto-normal.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/GINECOLOGIA+Y+OBSTETRICIA/guia-atencion-parto-normal.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Triage obstetrico.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/GINECOLOGIA+Y+OBSTETRICIA/Triage+obstetrico.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Manual_obstetricia_g.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/GINECOLOGIA+Y+OBSTETRICIA/Manual_obstetricia_g.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Williams_Obstetricia_Edicion_25_Espanol.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/GINECOLOGIA+Y+OBSTETRICIA/Williams_Obstetricia_Edicion_25_Espan%CC%83ol.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Manual-Obstetricia-y-Ginecologia-2018.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/GINECOLOGIA+Y+OBSTETRICIA/Manual-Obstetricia-y-Ginecologi%CC%81a-2018.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "wg2da.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/GINECOLOGIA+Y+OBSTETRICIA/wg2da.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Atencion inicial del Recien Nacido.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/GINECOLOGIA+Y+OBSTETRICIA/Atencio%CC%81n+inicial+del+Recie%CC%81n+Nacido.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Fecha probable de parto - Materno infantil - video 9.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/GINECOLOGIA+Y+OBSTETRICIA/Fecha+probable+de+parto+-+Materno+infantil+-+video+9.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Valoracion pelvica - Materno infantil - video 1.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/GINECOLOGIA+Y+OBSTETRICIA/Valoracio%CC%81n+pe%CC%81lvica+-+Materno+infantil+-+video+1.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "952b0b08-6785-4975-9f3d-5f50b42dcb01.mp4.html",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/GINECOLOGIA+Y+OBSTETRICIA/952b0b08-6785-4975-9f3d-5f50b42dcb01.mp4.html",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Maniobras de LEOPOLD _ Materno infantil _ video 4.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/GINECOLOGIA+Y+OBSTETRICIA/Maniobras+de+LEOPOLD+_+Materno+infantil+_+video+4.mp4",
                        tipo: "VIDEO"
                    }
                ]
            },
            {
                nombre: "SEXUALIDAD Y EDUCACION SEXUAL",
                recursos: [
                    {
                        nombre: "Libro Educacion de la sexualidad 2.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/SEXUALIDAD+Y+EDUCACION+SEXUAL/Libro+Educacion+de+la+sexualidad+2.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "educacion_sexual_dossier.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/SEXUALIDAD+Y+EDUCACION+SEXUAL/educacion_sexual_dossier.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Libro Sex.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/SEXUALIDAD+Y+EDUCACION+SEXUAL/Libro+Sex.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Sexualidad humana 6ta edicion.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/SEXUALIDAD+Y+EDUCACION+SEXUAL/Sexualidad+humana+6ta+edicion.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Sexualidad humana.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/SEXUALIDAD+Y+EDUCACION+SEXUAL/Sexualidad+humana.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Nuestrasexualidad-robert-crooks.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/SEXUALIDAD+Y+EDUCACION+SEXUAL/Nuestrasexualidad-robert-crooks.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Hablemos de sexualidad.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/SEXUALIDAD+Y+EDUCACION+SEXUAL/Hablemos+de+sexualidad.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Que es la orientacion sexual_ (Colombia).mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/SEXUALIDAD+Y+EDUCACION+SEXUAL/%C2%BFQue%CC%81+es+la+orientacio%CC%81n+sexual_+(Colombia).mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Etapas psicosexuales segun Freud.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/SEXUALIDAD+Y+EDUCACION+SEXUAL/Etapas+psicosexuales+segu%CC%81n+Freud.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "DERECHOS SEXUALES y REPRODUCTIVOS.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/SEXUALIDAD+Y+EDUCACION+SEXUAL/DERECHOS+SEXUALES+y+REPRODUCTIVOS.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "QUE ES LA DIVERSIDAD SEXUAL EXPLICACION FACIL.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/SEXUALIDAD+Y+EDUCACION+SEXUAL/%C2%BFQUE%CC%81+ES+LA+DIVERSIDAD+SEXUAL+EXPLICACIO%CC%81N+FA%CC%81CIL.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Hablemos sobre la #Sexualidad.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/3ER+SEMESTRE/SEXUALIDAD+Y+EDUCACION+SEXUAL/Hablemos+sobre+la+#Sexualidad.mp4",
                        tipo: "VIDEO"
                    }
                ]
            }
        ]
    },
    {
        semester: "4TO SEMESTRE",
        materias: [
            {
                nombre: "NUTRICION Y DIETOTERAPIA",
                recursos: [
                    {
                        nombre: "Nutricion- Que es la nutricion.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/NUTRICION+Y+DIETOTERAPIA/Nutricio%CC%81n-+%C2%BFQue%CC%81+es+la+nutricio%CC%81n.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Nutricion en Diferentes Etapas de la Vida.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/NUTRICION+Y+DIETOTERAPIA/Nutricio%CC%81n+en+Diferentes+Etapas+de+la+Vida.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Las leyes de la alimentacion.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/NUTRICION+Y+DIETOTERAPIA/Las+leyes+de+la+alimentacio%CC%81n.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Excrecion (APARATO EXCRETOR).mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/NUTRICION+Y+DIETOTERAPIA/Excrecio%CC%81n+(APARATO+EXCRETOR).mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Trastornos de la articulacion temporomandibular.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/NUTRICION+Y+DIETOTERAPIA/Trastornos+de+la+articulacio%CC%81n+temporomandibular.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "COLORANTES - Aditivos alimentarios Tema 4.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/NUTRICION+Y+DIETOTERAPIA/COLORANTES+-+Aditivos+alimentarios+Tema+4.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "10 ENFERMEDADES DEL SISTEMA ENDOCRINO MAS COMUNES Que son, Causas y Sintomas.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/NUTRICION+Y+DIETOTERAPIA/10+ENFERMEDADES+DEL+SISTEMA+ENDOCRINO+MA%CC%81S+COMUNES+Que%CC%81+son,+Causas+y+Si%CC%81ntomas.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Consejos para iniciar la alimentacion complementaria de tu bebe.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/NUTRICION+Y+DIETOTERAPIA/Consejos+para+iniciar+la+alimentacio%CC%81n+complementaria+de+tu+bebe%CC%81.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Metabolismo y Rutas metabolicas (Anabolismo y Catabolismo) EN 11 MINUTOS!.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/NUTRICION+Y+DIETOTERAPIA/Metabolismo+y+Rutas+metabo%CC%81licas+(Anabolismo+y+Catabolismo)+EN+11+MINUTOS!.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Enfermedades transmitidas por los alimentos- Que problema.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/NUTRICION+Y+DIETOTERAPIA/Enfermedades+transmitidas+por+los+alimentos-+%C2%BFQue%CC%81+problema.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Vigorexia..mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/NUTRICION+Y+DIETOTERAPIA/Vigorexia..mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Educacion en Nutricion.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/NUTRICION+Y+DIETOTERAPIA/Educacio%CC%81n+en+Nutricio%CC%81n.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Aspectos Tecnicos-Dieteticos por Regimen de Alimentacion.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/NUTRICION+Y+DIETOTERAPIA/Aspectos+Te%CC%81cnicos-Diete%CC%81ticos+por+Re%CC%81gimen+de+Alimentacio%CC%81n.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Funcion del sistema digestivo.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/NUTRICION+Y+DIETOTERAPIA/Funcio%CC%81n+del+sistema+digestivo.mp4",
                        tipo: "VIDEO"
                    }
                ]
            },
            {
                nombre: "ENFERMERIA QUIRURGICA",
                recursos: [
                    {
                        nombre: "LIBRO ENFERMERIA Qx-TOMO1.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/ENFERMERIA+QUIRURGICA/LIBRO+ENFERMERIA+Qx-TOMO1.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Puesta de bata y enguantado (Tecnica asistida, y no asistida).mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/ENFERMERIA+QUIRURGICA/Puesta+de+bata+y+enguantado+(Te%CC%81cnica+asistida,+y+no+asistida).mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Soluciones antisepticas y desinfectantes.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/ENFERMERIA+QUIRURGICA/Soluciones+antise%CC%81pticas+y+desinfectantes.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Conteo de Material Blanco en Block Quirurgico.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/ENFERMERIA+QUIRURGICA/Conteo+de+Material+Blanco+en+Block+Quiru%CC%81rgico.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "2.4 Homeostasis parte 1 Fundamentos de las  SOLUCIONES INTRAVENOSAS - Bases conceptuales- PARTE 1.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/ENFERMERIA+QUIRURGICA/2.4+Homeostasis+parte+1+Fundamentos+de+las++SOLUCIONES+INTRAVENOSAS+-+Bases+conceptuales-+PARTE+1.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "3.2Funciones de la Enfermera circulante y Enfermera instrumentista..mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/ENFERMERIA+QUIRURGICA/3.2Funciones+de+la+Enfermera+circulante+y+Enfermera+instrumentista..mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "1.11Cirugia Segura _Lista de Verificacion_.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/ENFERMERIA+QUIRURGICA/1.11Cirugi%CC%81a+Segura+_Lista+de+Verificacio%CC%81n_.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "1.9 Lavado quirurgico de manos.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/ENFERMERIA+QUIRURGICA/1.9+Lavado+quiru%CC%81rgico+de+manos.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "1.4 MOBILIARIO Y EQUIPOS EN EL QUIROFANO.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/ENFERMERIA+QUIRURGICA/1.4+MOBILIARIO+Y+EQUIPOS+EN+EL+QUIRO%CC%81FANO.mp4",
                        tipo: "VIDEO"
                    }
                ]
            },
            {
                nombre: "ENFERMERIA EN MEDICINA CRITICA",
                recursos: [
                    {
                        nombre: ".DS_Store",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/ENFERMERIA+EN+MEDICINA+CRITICA/.DS_Store",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Syllabus 2014.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/ENFERMERIA+EN+MEDICINA+CRITICA/Syllabus+2014.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Syllabus 2017.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/ENFERMERIA+EN+MEDICINA+CRITICA/Syllabus+2017.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Protocolos y procedimientos en el paciente critico_booksmedicos.org.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/ENFERMERIA+EN+MEDICINA+CRITICA/Protocolos+y+procedimientos+en+el+paciente+critico_booksmedicos.org.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Terapia intensiva(1).pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/ENFERMERIA+EN+MEDICINA+CRITICA/Terapia+intensiva(1).pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "El Libro de la UCI Paul Marino.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/ENFERMERIA+EN+MEDICINA+CRITICA/El+Libro+de+la+UCI+Paul+Marino.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Cuidados Intensivos.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/ENFERMERIA+EN+MEDICINA+CRITICA/Cuidados+Intensivos.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "intervencion de enfermeria en cuidados criticos.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/ENFERMERIA+EN+MEDICINA+CRITICA/intervencion+de+enfermeria+en+cuidados+criticos.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Cuidados Generales del paciente critico con COVID 19.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/ENFERMERIA+EN+MEDICINA+CRITICA/Cuidados+Generales+del+paciente+cri%CC%81tico+con+COVID+19.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "UN DIA EN LA UCI.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/ENFERMERIA+EN+MEDICINA+CRITICA/UN+DI%CC%81A+EN+LA+UCI.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Cuidado humanizado en enfermeria.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/ENFERMERIA+EN+MEDICINA+CRITICA/Cuidado+humanizado+en+enfermeria.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "PROTOCOLO DE ROLES DE CODIGO AZUL CLINICA OCCIDENTE 2019.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/ENFERMERIA+EN+MEDICINA+CRITICA/PROTOCOLO+DE+ROLES+DE+CO%CC%81DIGO+AZUL+CLINICA+OCCIDENTE+2019.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Entrenamiento en cuidados criticos y urgencias de la linea.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/ENFERMERIA+EN+MEDICINA+CRITICA/Entrenamiento+en+cuidados+cri%CC%81ticos+y+urgencias+de+la+li%CC%81nea.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Unidad de Cuidados Intensivos UCI.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/ENFERMERIA+EN+MEDICINA+CRITICA/Unidad+de+Cuidados+Intensivos+UCI.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Cadena de Supervivencia RCP (Reanimacion Cardiopulmonar).mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/ENFERMERIA+EN+MEDICINA+CRITICA/Cadena+de+Supervivencia+RCP+(Reanimacio%CC%81n+Cardiopulmonar).mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "11-05-20. Monitorizacion Basica del Paciente Critico.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/ENFERMERIA+EN+MEDICINA+CRITICA/11-05-20.+Monitorizacio%CC%81n+Ba%CC%81sica+del+Paciente+Cri%CC%81tico.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Pre y Post Operatorio.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/ENFERMERIA+EN+MEDICINA+CRITICA/Pre+y+Post+Operatorio.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Ventilacion mecanica Principios basicos para enfermeria.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/ENFERMERIA+EN+MEDICINA+CRITICA/Ventilacio%CC%81n+meca%CC%81nica+Principios+ba%CC%81sicos+para+enfermeri%CC%81a.mp4",
                        tipo: "VIDEO"
                    }
                ]
            },
            {
                nombre: "BIOETICA Y LEGISLACION",
                recursos: [
                    {
                        nombre: "Que es la CONAMED.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/BIOETICA+Y+LEGISLACION/%C2%BFQue%CC%81+es+la+CONAMED.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "El Compromiso.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/BIOETICA+Y+LEGISLACION/El+Compromiso.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Dimension Etica.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/BIOETICA+Y+LEGISLACION/Dimensio%CC%81n+E%CC%81tica.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "etica profecional en enfermeria.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/BIOETICA+Y+LEGISLACION/etica+profecional+en+enfermeria.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Concepto de los Derechos humanos.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/BIOETICA+Y+LEGISLACION/Concepto+de+los+Derechos+humanos.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Derechos Humanos en Salud.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/BIOETICA+Y+LEGISLACION/Derechos+Humanos+en+Salud.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Responsabilidad.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/BIOETICA+Y+LEGISLACION/Responsabilidad.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Etica en la investigacion con humanos -  - UPV.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/BIOETICA+Y+LEGISLACION/E%CC%81tica+en+la+investigacio%CC%81n+con+humanos+-++-+UPV.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Respeto.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/BIOETICA+Y+LEGISLACION/Respeto.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Que es el trasplante de organos.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/BIOETICA+Y+LEGISLACION/Que%CC%81+es+el+trasplante+de+o%CC%81rganos.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "principios de la bioetica.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/BIOETICA+Y+LEGISLACION/principios+de+la+bioetica.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "EMPATIA- RELACION ENFERMERO PACIENTE.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/BIOETICA+Y+LEGISLACION/EMPATIA-+RELACION+ENFERMERO+PACIENTE.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "CONAMED - CECAMED (CAMECAM).mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/BIOETICA+Y+LEGISLACION/CONAMED+-+CECAMED+(CAMECAM).mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "CODIGO DE ETICA PARA PROFESIONALES DE LA SALUD.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/BIOETICA+Y+LEGISLACION/CODIGO+DE+ETICA+PARA+PROFESIONALES+DE+LA+SALUD.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "integridad.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/BIOETICA+Y+LEGISLACION/integridad.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Codigo de Etica para las Enfermeras y Enfermeros en Mexico.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/BIOETICA+Y+LEGISLACION/Co%CC%81digo+de+E%CC%81tica+para+las+Enfermeras+y+Enfermeros+en+Me%CC%81xico.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "DERECHO PENAL II- Delitos contra la vida y la salud.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/BIOETICA+Y+LEGISLACION/DERECHO+PENAL+II-+Delitos+contra+la+vida+y+la+salud.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Que es el TOLERANCIA.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/BIOETICA+Y+LEGISLACION/\ud83d\udd34%C2%BFQue+es+el+TOLERANCIA.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Bioetica.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/BIOETICA+Y+LEGISLACION/Bioe%CC%81tica.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Derechos Humanos y Salud Mental.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/BIOETICA+Y+LEGISLACION/Derechos+Humanos+y+Salud+Mental.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Que es la solidaridad.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/4TO+SEMESTRE/BIOETICA+Y+LEGISLACION/Que+es+la+solidaridad.mp4",
                        tipo: "VIDEO"
                    }
                ]
            }
        ]
    },
    {
        semester: "5TO SEMESTRE",
        materias: [
            {
                nombre: "INGLES II",
                recursos: []
            },
            {
                nombre: "ENFERMERIA E INTERCULTURALIDAD",
                recursos: [
                    {
                        nombre: "Historia De La Fitoterapia.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+E+INTERCULTURALIDAD/Historia+De+La+Fitoterapia.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "ENF INTERCULTURALIDAD.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+E+INTERCULTURALIDAD/ENF+INTERCULTURALIDAD.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Declaracion Universal de Derechos Humanos.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+E+INTERCULTURALIDAD/Declaracion+Universal+de+Derechos+Humanos.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "ANTROPOLOGIA DE LOS CUIDADOS.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+E+INTERCULTURALIDAD/ANTROPOLOGIA+DE+LOS+CUIDADOS.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "MEDICINA, ACADEMIA Y DESARROLLO SOCIAL.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+E+INTERCULTURALIDAD/MEDICINA,+ACADEMIA+Y+DESARROLLO+SOCIAL.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Desarrollo del pensamiento.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+E+INTERCULTURALIDAD/Desarrollo+del+pensamiento.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Relaciones Interculturales.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+E+INTERCULTURALIDAD/Relaciones+Interculturales.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "modelo_intercultural_09.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+E+INTERCULTURALIDAD/modelo_intercultural_09.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Interculturalidad-web.pdf.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+E+INTERCULTURALIDAD/Interculturalidad-web.pdf.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Historia de la interculturalidad.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+E+INTERCULTURALIDAD/Historia+de+la+interculturalidad.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Pueblos Indigenas, miradas y debates actuales- Salud Intercultural.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+E+INTERCULTURALIDAD/Pueblos+Indi%CC%81genas,+miradas+y+debates+actuales-+Salud+Intercultural.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "ARTICULO 2 EXPLICACION - CONSTITUCION MEXICANA (2017) _DERECHO DE LOS PUEBLOS INDIGENAS_.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+E+INTERCULTURALIDAD/ARTI%CC%81CULO+2%C2%B0+EXPLICACIO%CC%81N+-+CONSTITUCIO%CC%81N+MEXICANA+(2017)+_DERECHO+DE+LOS+PUEBLOS+INDI%CC%81GENAS_.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Que es la interculturalidad.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+E+INTERCULTURALIDAD/%C2%BFQue%CC%81+es+la+interculturalidad.mp4",
                        tipo: "VIDEO"
                    }
                ]
            },
            {
                nombre: "PEDIATRIA",
                recursos: [
                    {
                        nombre: "Martinez y Martinez. Pediatria del nino y del adolescente.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/PEDIATRIA/Marti%CC%81nez+y+Marti%CC%81nez.+Pediatri%CC%81a+del+nin%CC%83o+y+del+adolescente.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Manual Washington de Pediatria.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/PEDIATRIA/Manual+Washington+de+Pediatria.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Guia de Atencion Integral al nino ostomizado.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/PEDIATRIA/Guia+de+Atencion+Integral+al+nin%CC%83o+ostomizado.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Pediatria de Bolsillo - Paritosh Prasad.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/PEDIATRIA/Pediatria+de+Bolsillo+-+Paritosh+Prasad.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Manual de Pediatria.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/PEDIATRIA/Manual+de+Pediatria.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Pediatria Secretos (4 Edicion).pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/PEDIATRIA/Pediatri%CC%81a+Secretos+(4\u00aa+Edicio%CC%81n).pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Pediatria martinez.salud.y.enfermedad.de.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/PEDIATRIA/Pediatria+martinez.salud.y.enfermedad.de.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "EXPLORACION NEUROLOGICA NEONATAL III.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/PEDIATRIA/EXPLORACIO%CC%81N+NEUROLO%CC%81GICA+NEONATAL+III.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "RCP PEDIATRICA.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/PEDIATRIA/RCP+PEDIA%CC%81TRICA.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Cirugia Infantil- Quemaduras en ninos [Clinica Las Condes].mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/PEDIATRIA/Cirugi%CC%81a+Infantil-+Quemaduras+en+nin%CC%83os+[Cli%CC%81nica+Las+Condes].mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "PEDIATRIA 8.2- Intoxicaciones en Pediatria.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/PEDIATRIA/PEDIATRIA+8.2-+Intoxicaciones+en+Pediatri%CC%81a.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "EXPLORACION NEUROLOGICA NEONATAL II.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/PEDIATRIA/EXPLORACIO%CC%81N+NEUROLO%CC%81GICA+NEONATAL+II.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "1   VALORACION NEUROLOGICA PEDIATRICA.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/PEDIATRIA/1+++VALORACIO%CC%81N+NEUROLO%CC%81GICA+PEDIA%CC%81TRICA.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Quemaduras- Clasificacion y tratamiento.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/PEDIATRIA/Quemaduras-+Clasificacio%CC%81n+y+tratamiento.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Intubacion Neonatal.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/PEDIATRIA/Intubacio%CC%81n+Neonatal.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "EXPLORACION NEUROLOGICA NEONATAL I.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/PEDIATRIA/EXPLORACIO%CC%81N+NEUROLO%CC%81GICA+NEONATAL+I.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "EXPLORACION NEUROLOGICA NEONATAL IV.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/PEDIATRIA/EXPLORACIO%CC%81N+NEUROLO%CC%81GICA+NEONATAL+IV.mp4",
                        tipo: "VIDEO"
                    }
                ]
            },
            {
                nombre: "ENFERMERIA PEDIATRICA",
                recursos: [
                    {
                        nombre: "Enfermeria pediatrica 2a Edicion booksmedicos.org.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+PEDIATRICA/Enfermeria+pediatrica+2a+Edicion+booksmedicos.org.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Manual rcp neonatal 2016.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+PEDIATRICA/Manual+rcp+neonatal+2016.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Manual de Enfermeria Pediatrica de Wong -.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+PEDIATRICA/Manual+de+Enfermeria+Pediatrica+de+Wong+-.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Manual de pediatria-Salustia-Granados.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+PEDIATRICA/Manual+de+pediatria-Salustia-Granados.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "REANIMACION NEONATAL 7ma Edicion.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+PEDIATRICA/REANIMACIO%CC%81N+NEONATAL+7ma+Edicio%CC%81n.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "PAC Neonatologia 4 edicion.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+PEDIATRICA/PAC+Neonatologia+4+edicio%CC%81n.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "EL RECIEN NACIDO.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+PEDIATRICA/EL+RECIEN+NACIDO.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Enfermeria pediatrica VALVERDE.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+PEDIATRICA/Enfermeria+pediatrica+VALVERDE.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Manual de Procedimientos de Enfermeria en las Unidades Neonatales.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+PEDIATRICA/Manual+de+Procedimientos+de+Enfermeri%CC%81a+en+las+Unidades+Neonatales.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Temas de Enfermeria Pediatrica.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+PEDIATRICA/Temas+de+Enfermeria+Pediatrica.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Manual AMIR - Enfermeria Pediatrica - 4a 2014.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+PEDIATRICA/Manual+AMIR+-+Enfermeria+Pediatrica+-+4a+2014.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Cateter percutaneo neonatal.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+PEDIATRICA/Cate%CC%81ter+percuta%CC%81neo+neonatal.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "TRIAGE en el servicio de urgencias.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+PEDIATRICA/TRIAGE+en+el+servicio+de+urgencias.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "CATETER UMBILICAL.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+PEDIATRICA/CATETER+UMBILICAL.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Nutricion parenteral en ninos.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+PEDIATRICA/Nutricio%CC%81n+parenteral+en+nin%CC%83os.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "VENTILACION CON AMBU PEDIATRICO.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+PEDIATRICA/VENTILACIO%CC%81N+CON+AMBU%CC%81+PEDIA%CC%81TRICO.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Diferencia entre urgencia y emergencia.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+PEDIATRICA/Diferencia+entre+urgencia+y+emergencia.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Intervencion de enfermeria en la instalacion de CPAP nasal.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+PEDIATRICA/Intervencio%CC%81n+de+enfermeri%CC%81a+en+la+instalacio%CC%81n+de+CPAP+nasal.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Obtencion de muestra de sangre para hemocultivo en menores de 5 anos.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+PEDIATRICA/Obtencio%CC%81n+de+muestra+de+sangre+para+hemocultivo+en+menores+de+5+an%CC%83os.mp4",
                        tipo: "VIDEO"
                    }
                ]
            },
            {
                nombre: "PSICOLOGI%CC%81A",
                recursos: [
                    {
                        nombre: "EL JUEGO COMO FACTOR DE SOCIALIZACION DEL NINO.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/PSICOLOGI%CC%81A/EL+JUEGO+COMO+FACTOR+DE+SOCIALIZACIO%CC%81N+DEL+NIN%CC%83O.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "BASES BIOLOGICAS DE LA CONDUCTA.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/PSICOLOGI%CC%81A/BASES+BIOLOGICAS+DE+LA+CONDUCTA.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Historias de la salud mental para un nuevo tiempo.pdf  version 1.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/PSICOLOGI%CC%81A/Historias+de+la+salud+mental+para+un+nuevo+tiempo.pdf+\u00b7+versio%CC%81n+1.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "La psicologia humanista Carl Roger y Abrham Maslow.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/PSICOLOGI%CC%81A/La+psicologi%CC%81a+humanista+Carl+Roger+y+Abrham+Maslow.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Introduccion a la Psicologia.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/PSICOLOGI%CC%81A/Introduccio%CC%81n+a+la+Psicologi%CC%81a.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Teorias de la personalidad.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/PSICOLOGI%CC%81A/Teorias+de+la+personalidad.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Jugar... Un asunto muy serio! Importancia del juego en el desarrollo infantil.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/PSICOLOGI%CC%81A/Jugar...+\u00a1Un+asunto+muy+serio!+Importancia+del+juego+en+el+desarrollo+infantil.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "La importancia del juego en el desarrollo del nino.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/PSICOLOGI%CC%81A/La+importancia+del+juego+en+el+desarrollo+del+nin%CC%83o.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "procesos cognitivos.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/PSICOLOGI%CC%81A/procesos+cognitivos.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Experimento de Piaget etapa-preoperacional.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/PSICOLOGI%CC%81A/Experimento+de+Piaget+etapa-preoperacional.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Pensamiento - Sensacion-Percepcion.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/PSICOLOGI%CC%81A/Pensamiento+-+Sensacion-Percepcio%CC%81n.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "hemisferios y lobulos cerebrales.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/PSICOLOGI%CC%81A/hemisferios+y+lo%CC%81bulos+cerebrales.mp4",
                        tipo: "VIDEO"
                    }
                ]
            },
            {
                nombre: "ENFERMERIA BASADA EN EVIDENCIA (EBE)",
                recursos: [
                    {
                        nombre: "Practica Basada en Evidencia PBE.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+BASADA+EN+EVIDENCIA+(EBE)/Practica+Basada+en+Evidencia+PBE.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "LIBRO DE ADMINISTRACION DE LOS SERVICIOS DE ENFERMERIA.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+BASADA+EN+EVIDENCIA+(EBE)/LIBRO+DE+ADMINISTRACIO%CC%81N+DE+LOS+SERVICIOS+DE+ENFERMERI%CC%81A.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "GENEREALIDADES DE LA EBE.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+BASADA+EN+EVIDENCIA+(EBE)/GENEREALIDADES+DE+LA+EBE.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "QUE ES LA EBE.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+BASADA+EN+EVIDENCIA+(EBE)/QUE%CC%81+ES+LA+EBE.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "EBE Hacia la excelencia en cuidados.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+BASADA+EN+EVIDENCIA+(EBE)/EBE+Hacia+la+excelencia+en+cuidados.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Los pilares de las Practicas Basadas en Evidencias.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+BASADA+EN+EVIDENCIA+(EBE)/Los+pilares+de+las+Pra%CC%81cticas+Basadas+en+Evidencias.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Enfermeria Basada en la Evidencia.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+BASADA+EN+EVIDENCIA+(EBE)/Enfermeri%CC%81a+Basada+en+la+Evidencia.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Que es la Enfermeria Basada en Evidencia (EBE).mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/5TO+SEMESTRE/ENFERMERIA+BASADA+EN+EVIDENCIA+(EBE)/%C2%BFQue+es+la+Enfermeri%CC%81a+Basada+en+Evidencia+(EBE).mp4",
                        tipo: "VIDEO"
                    }
                ]
            }
        ]
    },
    {
        semester: "6TO SEMESTRE",
        materias: [
            {
                nombre: "CUIDADO DEL NINO Y DEL ADOLESCENTE",
                recursos: [
                    {
                        nombre: "crecimiento y desarrollo.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/CUIDADO+DEL+NINO+Y+DEL+ADOLESCENTE/crecimiento+y+desarrollo.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "doku.pub_tratado-de-enfermeria-del-nio-y-el-adolescente.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/CUIDADO+DEL+NINO+Y+DEL+ADOLESCENTE/doku.pub_tratado-de-enfermeria-del-nio-y-el-adolescente.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "crecimiento-y-desarrollo-en-pediatria.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/CUIDADO+DEL+NINO+Y+DEL+ADOLESCENTE/crecimiento-y-desarrollo-en-pediatria.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "CUIDADOS EN EL CONTROL DE CRED DEL ADOLESCENTE.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/CUIDADO+DEL+NINO+Y+DEL+ADOLESCENTE/CUIDADOS+EN+EL+CONTROL+DE+CRED+DEL+ADOLESCENTE.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "ETAPAS DE LA ADOLECENCIA.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/CUIDADO+DEL+NINO+Y+DEL+ADOLESCENTE/ETAPAS+DE+LA+ADOLECENCIA.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Crecimiento, Desarrollo y Madurez del Nino..mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/CUIDADO+DEL+NINO+Y+DEL+ADOLESCENTE/Crecimiento,+Desarrollo+y+Madurez+del+Nin%CC%83o..mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "CAMBIOS FISICOS Y PSICOLOGICOS EN LA ADOLENCIA.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/CUIDADO+DEL+NINO+Y+DEL+ADOLESCENTE/CAMBIOS+FISICOS+Y+PSICOLOGICOS+EN+LA+ADOLENCIA.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Atencion integral para la primera infancia.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/CUIDADO+DEL+NINO+Y+DEL+ADOLESCENTE/Atencio%CC%81n+integral+para+la+primera+infancia.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Desarrollo Integral de los ninos.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/CUIDADO+DEL+NINO+Y+DEL+ADOLESCENTE/Desarrollo+Integral+de+los+nin%CC%83os.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "DESARROLLO DEL ADOLECECIA.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/CUIDADO+DEL+NINO+Y+DEL+ADOLESCENTE/DESARROLLO+DEL+ADOLECECIA.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Desarrollo cerebral del nino.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/CUIDADO+DEL+NINO+Y+DEL+ADOLESCENTE/Desarrollo+cerebral+del+nin%CC%83o.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "CRECIMIENTO Y DESARROLLO.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/CUIDADO+DEL+NINO+Y+DEL+ADOLESCENTE/CRECIMIENTO+Y+DESARROLLO.mp4",
                        tipo: "VIDEO"
                    }
                ]
            },
            {
                nombre: "TECNOLOGIAS DE INFORMACION Y COMPUTACION II",
                recursos: [
                    {
                        nombre: "Libro 03 - Las tecnologias de la informacion y la comunicacion.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/TECNOLOGIAS+DE+INFORMACION+Y+COMPUTACION+II/Libro+03+-+Las+tecnologi%CC%81as+de+la+informacio%CC%81n+y+la+comunicacio%CC%81n.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Libro TIC (2)-1-76 (1).pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/TECNOLOGIAS+DE+INFORMACION+Y+COMPUTACION+II/Libro+TIC+(2)-1-76+(1).pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "06-Tecnologia de Informacion Y Comunicacion.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/TECNOLOGIAS+DE+INFORMACION+Y+COMPUTACION+II/06-Tecnologia+de+Informacion+Y+Comunicacion.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "videoplayback (3).mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/TECNOLOGIAS+DE+INFORMACION+Y+COMPUTACION+II/videoplayback+(3).mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "La Enfermeria del Siglo XXI.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/TECNOLOGIAS+DE+INFORMACION+Y+COMPUTACION+II/La+Enfermeri%CC%81a+del+Siglo+XXI.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "videoplayback (2).mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/TECNOLOGIAS+DE+INFORMACION+Y+COMPUTACION+II/videoplayback+(2).mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "TIC en Enfermeria- uso de recursos en red para la competencia enfermera.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/TECNOLOGIAS+DE+INFORMACION+Y+COMPUTACION+II/TIC+en+Enfermeri%CC%81a-+uso+de+recursos+en+red+para+la+competencia+enfermera.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Que es la tecnologia (1).mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/TECNOLOGIAS+DE+INFORMACION+Y+COMPUTACION+II/%C2%BFQue%CC%81+es+la+tecnologi%CC%81a+(1).mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "videoplayback (7).mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/TECNOLOGIAS+DE+INFORMACION+Y+COMPUTACION+II/videoplayback+(7).mp4",
                        tipo: "VIDEO"
                    }
                ]
            },
            {
                nombre: "GERIATRIA",
                recursos: [
                    {
                        nombre: "Enfermeria Gerontologica.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/GERIATRIA/Enfermeria+Gerontologica.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "geriatria_y_geronto.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/GERIATRIA/geriatria_y_geronto.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Principios_de_geriatria_y_gerontologia.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/GERIATRIA/Principios_de_geriatria_y_gerontologia.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "GERIATRIA PARA RESIDENTES.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/GERIATRIA/GERIATRIA+PARA+RESIDENTES.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Manual-Geriatria.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/GERIATRIA/Manual-Geriatria.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Manual-de-buena-practica-en-cuidados-a-las-personas-mayores.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/GERIATRIA/Manual-de-buena-practica-en-cuidados-a-las-personas-mayores.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "MOVILIZACION HACIA ARRIBA DE LA CAMA.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/GERIATRIA/MOVILIZACIO%CC%80N+HACIA+ARRIBA+DE+LA+CAMA.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Tecnicas de movilizacion- De decubito supino a decubito lateral..mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/GERIATRIA/Te%CC%81cnicas+de+movilizacio%CC%81n-+De+decu%CC%81bito+supino+a+decu%CC%81bito+lateral..mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Tecnicas de movilizacion- De sedestacion en sedestacion..mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/GERIATRIA/Te%CC%81cnicas+de+movilizacio%CC%81n-+De+sedestacio%CC%81n+en+sedestacio%CC%81n..mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Geriatria enfermeria.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/GERIATRIA/Geriatri%CC%81a+enfermeri%CC%81a.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "HIGIENE DE BOCA, OIDOS Y OJOS.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/GERIATRIA/HIGIENE+DE+BOCA,+OIDOS+Y+OJOS.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Tecnicas de movilizacion- De sedestacion a decubito supino..mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/GERIATRIA/Te%CC%81cnicas+de+movilizacio%CC%81n-+De+sedestacio%CC%81n+a+decu%CC%81bito+supino..mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "videoplayback.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/GERIATRIA/videoplayback.mp4",
                        tipo: "VIDEO"
                    }
                ]
            },
            {
                nombre: "ENFERMEDADES CRONICO DEGENERATIVAS",
                recursos: [
                    {
                        nombre: "221015Fisiopatologia.y.patologia.general.basicas.para.ciencias.de.la.salud_booksmedicos.org.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/ENFERMEDADES+CRONICO+DEGENERATIVAS/221015Fisiopatologia.y.patologia.general.basicas.para.ciencias.de.la.salud_booksmedicos.org.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "06 ENF 331PROYECTO TERMINADO.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/ENFERMEDADES+CRONICO+DEGENERATIVAS/06+ENF+331PROYECTO+TERMINADO.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "ENGFERMEDADES CRONO DEGENERATIVAS.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/ENFERMEDADES+CRONICO+DEGENERATIVAS/ENGFERMEDADES+CRONO+DEGENERATIVAS.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "06 ENF 520 ESTILOS DE VIDA EN ADULTOS Y ADULTOS MAYORES Y EL APARECIMIENTO DE ENFERMEDADES CRONICO DEGENERAT.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/ENFERMEDADES+CRONICO+DEGENERATIVAS/06+ENF+520+ESTILOS+DE+VIDA+EN+ADULTOS+Y+ADULTOS+MAYORES+Y+EL+APARECIMIENTO+DE+ENFERMEDADES+CRONICO+DEGENERAT.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Cuidado-Enfermeria-en-situaciones-de-enfermedad-cronica-uflip.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/ENFERMEDADES+CRONICO+DEGENERATIVAS/Cuidado-Enfermeria-en-situaciones-de-enfermedad-cronica-uflip.pdf",
                        tipo: "LIBRO"
                    }
                ]
            },
            {
                nombre: "ENFERMERIA Y EL ADULTO MAYOR",
                recursos: [
                    {
                        nombre: "Dialnet-AtencionDeEnfermeriaEnElAdultoMayor-6246978.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/ENFERMERIA+Y+EL+ADULTO+MAYOR/Dialnet-AtencionDeEnfermeriaEnElAdultoMayor-6246978.pdf",
                        tipo: "LIBRO"
                    }
                ]
            },
            {
                nombre: "METODOLOGIA DE LA INVESTIGACION I",
                recursos: [
                    {
                        nombre: "Introduccion a la Metodologia de la investigacion cientifica.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/METODOLOGIA+DE+LA+INVESTIGACION+I/Introduccion+a+la+Metodologia+de+la+investigacion+cientifica.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "HERNANDEZ SAMPIERI. sexta edicion.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/METODOLOGIA+DE+LA+INVESTIGACION+I/HERNA%CC%81NDEZ+SAMPIERI.+sexta+edicio%CC%81n.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Metodologia del marco logico para la planificacion, el seguimiento y la evaluacion de proyectos y programas.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/METODOLOGIA+DE+LA+INVESTIGACION+I/Metodologi%CC%81a+del+marco+lo%CC%81gico+para+la+planificacio%CC%81n,+el+seguimiento+y+la+evaluacio%CC%81n+de+proyectos+y+programas.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Metodologia_de_la_investigacion (1).pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/METODOLOGIA+DE+LA+INVESTIGACION+I/Metodologia_de_la_investigacion+(1).pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Metodologia-de-la-InvestigaciAn_Sampieri.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/METODOLOGIA+DE+LA+INVESTIGACION+I/Metodologia-de-la-InvestigaciA%CC%83\u00b3n_Sampieri.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "metodologia de la investigacion 3ra Edicion.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/METODOLOGIA+DE+LA+INVESTIGACION+I/metodologia+de+la+investigacion+3ra+Edicion.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Metodologia_para_que.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/METODOLOGIA+DE+LA+INVESTIGACION+I/Metodologia_para_que.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Sautu_el-marco-teorico-en-la-investigacion-cualitativa.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/METODOLOGIA+DE+LA+INVESTIGACION+I/Sautu_el-marco-teo%CC%81rico-en-la-investigacio%CC%81n-cualitativa.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Conceptos basicos metodologia de la investigacion. Estructura.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/METODOLOGIA+DE+LA+INVESTIGACION+I/Conceptos+ba%CC%81sicos+metodologi%CC%81a+de+la+investigacio%CC%81n.+Estructura.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "videoplayback (5).mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/METODOLOGIA+DE+LA+INVESTIGACION+I/videoplayback+(5).mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "videoplayback (4).mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/METODOLOGIA+DE+LA+INVESTIGACION+I/videoplayback+(4).mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "COMO HACER LA METODOLOGIA DE LA INVESTIGACION - ENFOQUE DE LA INVESTIGACION.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/METODOLOGIA+DE+LA+INVESTIGACION+I/CO%CC%81MO+HACER+LA+METODOLOGI%CC%81A+DE+LA+INVESTIGACIO%CC%81N+-+ENFOQUE+DE+LA+INVESTIGACIO%CC%81N.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Que es Metodologia de la Investigacion.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/METODOLOGIA+DE+LA+INVESTIGACION+I/%C2%BFQue%CC%81+es+Metodologi%CC%81a+de+la+Investigacio%CC%81n.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "videoplayback (6).mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/METODOLOGIA+DE+LA+INVESTIGACION+I/videoplayback+(6).mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "videoplayback.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/6TO+SEMESTRE/METODOLOGIA+DE+LA+INVESTIGACION+I/videoplayback.mp4",
                        tipo: "VIDEO"
                    }
                ]
            }
        ]
    },
    {
        semester: "7MO SEMESTRE",
        materias: [
            {
                nombre: "PSIQUIATRIA",
                recursos: [
                    {
                        nombre: "AtencionEnfermeria (1).pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/PSIQUIATRIA/AtencionEnfermeria+(1).pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "EnfermeriaSM4aEdicion.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/PSIQUIATRIA/EnfermeriaSM4aEdicion.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Enfermeria psiquiatrica planes de cuidado medilibros.com.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/PSIQUIATRIA/Enfermeria+psiquiatrica+planes+de+cuidado+medilibros.com.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Enfermeria psiquiatrica Planes de cuidado.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/PSIQUIATRIA/Enfermeria+psiquiatrica+Planes+de+cuidado.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Fundamentos de Enfermeria en Psiquiatria Towsend.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/PSIQUIATRIA/Fundamentos+de+Enfermeria+en+Psiquiatria+Towsend.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Fundamentos de Psiquiatria.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/PSIQUIATRIA/Fundamentos+de+Psiquiatria.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Psiquiatria para medicos no psiquiatras_booksmedicos.org.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/PSIQUIATRIA/Psiquiatria+para+medicos+no+psiquiatras_booksmedicos.org.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Kaplan _ Sadock Manual de Bolsillo de Psiquiatria Clinica 6e_booksmedicos.org.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/PSIQUIATRIA/Kaplan+_+Sadock+Manual+de+Bolsillo+de+Psiquiatria+Clinica+6e_booksmedicos.org.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Enfermeras explican como realizar la maniobra de Heimlich en caso de atragantamiento.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/PSIQUIATRIA/Enfermeras+explican+co%CC%81mo+realizar+la+maniobra+de+Heimlich+en+caso+de+atragantamiento.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Valoracion paciente psiquiatrico.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/PSIQUIATRIA/Valoracio%CC%81n+paciente+psiquia%CC%81trico.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Contension paciente psiquiatrico.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/PSIQUIATRIA/Contensio%CC%81n+paciente+psiquia%CC%81trico.mp4",
                        tipo: "VIDEO"
                    }
                ]
            },
            {
                nombre: "METODOLOGIA DE LA INVESTIGACION II",
                recursos: [
                    {
                        nombre: "Metodologia de la Investigacion Hernandez Sampieri 6a Ed_booksmedicos.org.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/METODOLOGIA+DE+LA+INVESTIGACION+II/Metodologia+de+la+Investigacion+Hernandez+Sampieri+6a+Ed_booksmedicos.org.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Metodologia de la investigacion en salud_booksmedicos.org.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/METODOLOGIA+DE+LA+INVESTIGACION+II/Metodologia+de+la+investigacion+en+salud_booksmedicos.org.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Metodologia de la Investigacion para el area de la salud_booksmedicos.org.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/METODOLOGIA+DE+LA+INVESTIGACION+II/Metodologia+de+la+Investigacion+para+el+area+de+la+salud_booksmedicos.org.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Manual de Metodologia de la Investigacion Clinica.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/METODOLOGIA+DE+LA+INVESTIGACION+II/Manual+de+Metodologia+de+la+Investigacion+Clinica.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "20nov_Libro_ManualdePublicacionesAPA.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/METODOLOGIA+DE+LA+INVESTIGACION+II/20nov_Libro_ManualdePublicacionesAPA.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Metodologia en la investigacion(480P)_1.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/METODOLOGIA+DE+LA+INVESTIGACION+II/Metodologi%CC%81a+en+la+investigacio%CC%81n(480P)_1.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "COMO HACER LA METODOLOGIA DE LA INVESTIGACION _ ENFOQUE DE LA INVESTIGACION(720P_HD)_1.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/METODOLOGIA+DE+LA+INVESTIGACION+II/CO%CC%81MO+HACER+LA+METODOLOGI%CC%81A+DE+LA+INVESTIGACIO%CC%81N+_+ENFOQUE+DE+LA+INVESTIGACIO%CC%81N(720P_HD)_1.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Metodologia de Investigacion segun Sampieri(360P)_1.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/METODOLOGIA+DE+LA+INVESTIGACION+II/Metodologi%CC%81a+de+Investigacio%CC%81n+segu%CC%81n+Sampieri(360P)_1.mp4",
                        tipo: "VIDEO"
                    }
                ]
            },
            {
                nombre: "BIOESTADISTICA",
                recursos: [
                    {
                        nombre: "Bioestadistica, 3ra Edicion - Alfredo de Jesus Celis-FREELIBROS.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/BIOESTADISTICA/Bioestadi%CC%81stica,+3ra+Edicio%CC%81n+-+Alfredo+de+Jesu%CC%81s+Celis-FREELIBROS.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Joaquin tomas sabado.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/BIOESTADISTICA/Joaquin+tomas+sabado.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Bioestadistica Amigable 3a Edicion.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/BIOESTADISTICA/Bioestadistica+Amigable+3a+Edicion.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "RAFAEL_ALVAREZ_CACERES.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/BIOESTADISTICA/RAFAEL_ALVAREZ_CACERES.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "La Bioestadistica.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/BIOESTADISTICA/La+Bioestadi%CC%81stica.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Como funciona la Bioestadistica.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/BIOESTADISTICA/%C2%BFCo%CC%81mo+funciona+la+Bioestadi%CC%81stica.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "MEDIA, MODA Y MEDIANA Super facil _ Medidas de tendencia central.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/BIOESTADISTICA/MEDIA,+MODA+Y+MEDIANA+Super+facil+_+Medidas+de+tendencia+central.mp4",
                        tipo: "VIDEO"
                    }
                ]
            },
            {
                nombre: "ENFERMERIA EN MEDICINA FISICA Y REHABILITACION",
                recursos: [
                    {
                        nombre: "Manual de Fisioterapia 2a Edicion.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/ENFERMERIA+EN+MEDICINA+FISICA+Y+REHABILITACION/Manual+de+Fisioterapia+2a+Edicion.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Manual de Rehabilitacion Geriatrica.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/ENFERMERIA+EN+MEDICINA+FISICA+Y+REHABILITACION/Manual+de+Rehabilitacion+Geriatrica.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "REHABILITACION CARE BASICOS GUIA.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/ENFERMERIA+EN+MEDICINA+FISICA+Y+REHABILITACION/REHABILITACIO%CC%81N+CARE+BA%CC%81SICOS+GUI%CC%81A.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Manejo del Dolor en el Paciente Pediatrico(720P_HD)_1.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/ENFERMERIA+EN+MEDICINA+FISICA+Y+REHABILITACION/Manejo+del+Dolor+en+el+Paciente+Pedia%CC%81trico(720P_HD)_1.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Manejo del dolor en quemados adultos. Dra. Belen Larrea(720P_HD)_1.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/ENFERMERIA+EN+MEDICINA+FISICA+Y+REHABILITACION/Manejo+del+dolor+en+quemados+adultos.+Dra.+Belen+Larrea(720P_HD)_1.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Movilizacion Temprana de pacientes postrados en cama(720P_HD)_1.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/ENFERMERIA+EN+MEDICINA+FISICA+Y+REHABILITACION/Movilizacio%CC%81n+Temprana+de+pacientes+postrados+en+cama(720P_HD)_1.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Como Curar una Quemadura de Primer Grado(360P)_1.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/ENFERMERIA+EN+MEDICINA+FISICA+Y+REHABILITACION/Co%CC%81mo+Curar+una+Quemadura+de+Primer+Grado(360P)_1.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Equilibrio_ control motor y tratamiento en el paciente neurologico - Fisiofocus(720P_HD)_1.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/ENFERMERIA+EN+MEDICINA+FISICA+Y+REHABILITACION/Equilibrio_+control+motor+y+tratamiento+en+el+paciente+neurolo%CC%81gico+-+Fisiofocus(720P_HD)_1.mp4",
                        tipo: "VIDEO"
                    }
                ]
            },
            {
                nombre: "ENFERMERIA EN SALUD MENTAL",
                recursos: [
                    {
                        nombre: "Enfermeria Psiquiatrica y de la Salud Mental 4a Edicion_booksmedicos.org.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/ENFERMERIA+EN+SALUD+MENTAL/Enfermeria+Psiquiatrica+y+de+la+Salud+Mental+4a+Edicion_booksmedicos.org.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "doku.pub_barlow-manual-clinico-de-trastornos-psicologicos-1.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/ENFERMERIA+EN+SALUD+MENTAL/doku.pub_barlow-manual-clinico-de-trastornos-psicologicos-1.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Introduccion a la psicopatologia y la psiquiatria_booksmedicos.org.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/ENFERMERIA+EN+SALUD+MENTAL/Introduccion+a+la+psicopatologia+y+la+psiquiatria_booksmedicos.org.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Salud mental_ enfermeria psiquiatrica.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/ENFERMERIA+EN+SALUD+MENTAL/Salud+mental_+enfermeri%CC%81a+psiquia%CC%81trica.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Cuidados de Enfermeria en Salud Mental_booksmedicos.org.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/ENFERMERIA+EN+SALUD+MENTAL/Cuidados+de+Enfermeria+en+Salud+Mental_booksmedicos.org.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Introduccion a la enfermeria de la salud mental.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/ENFERMERIA+EN+SALUD+MENTAL/Introduccion+a+la+enfermeria+de+la+salud+mental.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Enfermeria   Intervencion en Crisis(720P_HD)_1.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/ENFERMERIA+EN+SALUD+MENTAL/Enfermeri%CC%81a+++Intervencion+en+Crisis(720P_HD)_1.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Enfermeria en Salud Mental(720P_HD)_1.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/7MO+SEMESTRE/ENFERMERIA+EN+SALUD+MENTAL/Enfermeri%CC%81a+en+Salud+Mental(720P_HD)_1.mp4",
                        tipo: "VIDEO"
                    }
                ]
            }
        ]
    },
    {
        semester: "8VO SEMESTRE",
        materias: [
            {
                nombre: "SEMINARIO DE TESIS",
                recursos: [
                    {
                        nombre: "Guia practica de investigacion en salud, OPS 2008.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/SEMINARIO+DE+TESIS/Guia+practica+de+investigacion+en+salud,+OPS+2008.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Copia de Metodologia de la Investigacion Hernandez Sampieri 6a Ed_booksmedicos.org.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/SEMINARIO+DE+TESIS/Copia+de+Metodologia+de+la+Investigacion+Hernandez+Sampieri+6a+Ed_booksmedicos.org.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Como Empezar una Tesis_booksmedicos.org.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/SEMINARIO+DE+TESIS/Como+Empezar+una+Tesis_booksmedicos.org.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Guia para elaborar la tesis_booksmedicos.org.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/SEMINARIO+DE+TESIS/Guia+para+elaborar+la+tesis_booksmedicos.org.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "230-El-Protocolo-de-Investigacion-Ignacio-Mendez.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/SEMINARIO+DE+TESIS/230-El-Protocolo-de-Investigacion-Ignacio-Mendez.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Como Elaborar y Asesorar una Investigacion de TESIS.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/SEMINARIO+DE+TESIS/Como+Elaborar+y+Asesorar+una+Investigacion+de+TESIS.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Manual para la elaboracion de tesis_booksmedicos.org.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/SEMINARIO+DE+TESIS/Manual+para+la+elaboracion+de+tesis_booksmedicos.org.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Manual de investigacion cuantitativa para enfermeria. FEACAP 2011.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/SEMINARIO+DE+TESIS/Manual+de+investigacion+cuantitativa+para+enfermeria.+FEACAP+2011.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Objetivos generales y especificos - Metodologia de titulacion - Udla en linea(720P_HD)_1.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/SEMINARIO+DE+TESIS/Objetivos+generales+y+especi%CC%81ficos+-+Metodologi%CC%81a+de+titulacio%CC%81n+-+Udla+en+li%CC%81nea(720P_HD)_1.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Justificacion De La Investigacion _ Trabajo De Investigacion(720P_HD)_1.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/SEMINARIO+DE+TESIS/Justificacion+De+La+Investigacio%CC%81n+_+Trabajo+De+Investigacion(720P_HD)_1.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Que es el marco teorico de una tesis - Dra. Rosario Martinez(720P_HD)_1.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/SEMINARIO+DE+TESIS/Que%CC%81+es+el+marco+teo%CC%81rico+de+una+tesis+-+Dra.+Rosario+Marti%CC%81nez(720P_HD)_1.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Defender la tesis(720P_HD)_1.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/SEMINARIO+DE+TESIS/Defender+la+tesis(720P_HD)_1.mp4",
                        tipo: "VIDEO"
                    }
                ]
            },
            {
                nombre: "TANATOLOGIA Y CUIDADOS PALIATIVOS",
                recursos: [
                    {
                        nombre: "Guia Cuidados Paliativos.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/TANATOLOGIA+Y+CUIDADOS+PALIATIVOS/Guia+Cuidados+Paliativos.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "457845132005.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/TANATOLOGIA+Y+CUIDADOS+PALIATIVOS/457845132005.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Tanatologia y bioetica.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/TANATOLOGIA+Y+CUIDADOS+PALIATIVOS/Tanatologi%CC%81a+y+bioe%CC%81tica.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Cuidados Paliativos.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/TANATOLOGIA+Y+CUIDADOS+PALIATIVOS/Cuidados+Paliativos.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "64 El duelo y su proceso.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/TANATOLOGIA+Y+CUIDADOS+PALIATIVOS/64+El+duelo+y+su+proceso.pdf",
                        tipo: "LIBRO"
                    }
                ]
            },
            {
                nombre: "CULTURA DEL MEDIO AMBIENTE Y SUSTENTABILIDAD",
                recursos: [
                    {
                        nombre: "Soto Article.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/CULTURA+DEL+MEDIO+AMBIENTE+Y+SUSTENTABILIDAD/Soto+Article.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Informe Cultura  Ambiente.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/CULTURA+DEL+MEDIO+AMBIENTE+Y+SUSTENTABILIDAD/Informe+Cultura++Ambiente.pdf",
                        tipo: "LIBRO"
                    }
                ]
            },
            {
                nombre: "GERENCIA EN ENFERMERIA",
                recursos: [
                    {
                        nombre: "Administracion.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/GERENCIA+EN+ENFERMERIA/Administracio%CC%81n.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "ADMINISTRACION DE LOS SERVICIOS DE ENFERMERIA(1).pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/GERENCIA+EN+ENFERMERIA/ADMINISTRACIO%CC%81N+DE+LOS+SERVICIOS+DE+ENFERMERI%CC%81A(1).pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Administracion y Gestion-comments.html",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/GERENCIA+EN+ENFERMERIA/Administracio%CC%81n+y+Gestio%CC%81n-comments.html",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Administracion de los servicios de Enfermeria.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/GERENCIA+EN+ENFERMERIA/Administracio%CC%81n+de+los+servicios+de+Enfermeri%CC%81a.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Administracion de los Servicios de Salud en Enfermeria.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/GERENCIA+EN+ENFERMERIA/Administracio%CC%81n+de+los+Servicios+de+Salud+en+Enfermeri%CC%81a.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Administracion De Enfermeria.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/GERENCIA+EN+ENFERMERIA/Administracio%CC%81n+De+Enfermeri%CC%81a.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Guia Gestion y Direccion de Enfermeria. Ann Marriner T. 8a Edicion.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/GERENCIA+EN+ENFERMERIA/Guia+Gestion+y+Direccion+de+Enfermeria.+Ann+Marriner+T.+8a+Edicion.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Administracion y Gestion.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/GERENCIA+EN+ENFERMERIA/Administracio%CC%81n+y+Gestio%CC%81n.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Administracion y mejora continua.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/GERENCIA+EN+ENFERMERIA/Administracion+y+mejora+continua.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Competencias en Administracion y Gestion en Enfermeria.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/GERENCIA+EN+ENFERMERIA/Competencias+en+Administracio%CC%81n+y+Gestio%CC%81n+en+Enfermeri%CC%81a.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Liderazgo Gestion en Los Servicios De Enfermeria.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/GERENCIA+EN+ENFERMERIA/Liderazgo+Gestio%CC%81n+en+Los+Servicios+De+Enfermeria.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Gestion de los Servicios de Salud.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/GERENCIA+EN+ENFERMERIA/Gestion+de+los+Servicios+de+Salud.mp4",
                        tipo: "VIDEO"
                    },
                    {
                        nombre: "Admistracion en la salud.mp4",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/GERENCIA+EN+ENFERMERIA/Admistracio%CC%81n+en+la+salud.mp4",
                        tipo: "VIDEO"
                    }
                ]
            },
            {
                nombre: "PROYECTOS INNOVADORES EN SALUD",
                recursos: [
                    {
                        nombre: "INNOVACION EN SALUD(1).pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/PROYECTOS+INNOVADORES+EN+SALUD/INNOVACION+EN+SALUD(1).pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "INNOVACION TECNOLOGICA EN SALUD.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/PROYECTOS+INNOVADORES+EN+SALUD/INNOVACION+TECNOLOGICA+EN+SALUD.pdf",
                        tipo: "LIBRO"
                    },
                    {
                        nombre: "Innovacion en Salud.pdf",
                        url: "https://sim4r4-public.s3-accelerate.amazonaws.com/enfermeriauvt-source/8VO+SEMESTRE/PROYECTOS+INNOVADORES+EN+SALUD/Innovacion+en+Salud.pdf",
                        tipo: "LIBRO"
                    }
                ]
            }
        ]
    },
];