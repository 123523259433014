export const ActitudesSources = [
    {
        title: "Respeto",
        alt: "Respeto a la vida y a los demás",
        description: 'Respeto a la vida y a los demás; a la dignidad humana, a los derechos humanos, a las leyes y al medio ambiente.',
        url: 'https://sim4r4-public.s3.amazonaws.com/enfermeriauvt-source/Images/Respeto.jpg',
    },
    {
        title: "Compromiso",
        alt: "Respeto a la vida y a los Compromiso social y vocación de servicio",
        description: 'Compromiso social y vocación de servicio',
        url: 'https://sim4r4-public.s3.amazonaws.com/enfermeriauvt-source/Images/Compromiso.jpg',
    },
    {
        title: "Solidaridad",
        alt: "Solidaridad y humanismo",
        description: 'Solidaridad y humanismo',
        url: 'https://sim4r4-public.s3.amazonaws.com/enfermeriauvt-source/Images/Solidaridad.jpg',
    },
    {
        title: "Disciplina",
        alt: "Disciplina",
        description: 'Disciplina',
        url: 'https://sim4r4-public.s3.amazonaws.com/enfermeriauvt-source/Images/Disciplina.jpg',
    },
    {
        title: "Inquietud intelectual",
        alt: "Inquietud intelectual",
        description: 'Inquietud intelectual',
        url: 'https://sim4r4-public.s3.amazonaws.com/enfermeriauvt-source/Images/Curiosidad.jpg',
    }
]