import React from 'react';
import List from "@material-ui/core/List";
import {Container} from "@material-ui/core";
import {blueGrey} from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import Accordion from '@material-ui/core/Accordion';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {MateriasSource} from "../Source/MateriasSource";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '80%',
        padding: '50px 50px',
        color: blueGrey[800],
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: '500',
    },
    semestre: {
        padding: '25px 0'
    },
    titles: {
        backgroundImage: `url("https://sim4r4-public.s3.amazonaws.com/enfermeriauvt-source/Images/Nurses.jpg")`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '220px',
        borderRadius: 8,
        marginLeft: '10px',
        marginTop: '10px',
        color: blueGrey[900],
        padding: '25px 35px'
    }
}));

export default function Materias(props) {
    const classes = useStyles();
    const details = [
        {
            title: "Horas Independientes",
            key: "horasIndependientes"
        },
        {
            title: "Horas Docentes",
            key: "horasDocentes"
        },
        {
            title: "Horas Prácticas",
            key: "horasPracticas"
        },
        {
            title: "Créditos",
            key: "creditos"
        },
        {
            title: "Seriación",
            key: "seriacion"
        },
        {
            title: "Objetivo",
            key: "objetivo"
        },

    ]
    return (
        <Container className={classes.root}>
            <div className={classes.titles}>
                <Typography variant="h2" gutterBottom>
                    Materias
                </Typography>
                <Typography variant="h3" gutterBottom style={{color: 'white'}}>
                    Plan de Estudios
                </Typography>
            </div>
            {MateriasSource.map(semestre => {
                return <div className={classes.semestre}>
                    <Typography variant='h4' style={{paddingBottom: '10px'}}>
                        {semestre.semestre}
                    </Typography>
                    {semestre.materias.map(materia => {
                        return <div>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>{materia.clave}: {materia.nombre}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <List dense={true}>
                                        {details.map(meta => {
                                            return <ListItem>
                                                <ListItemText
                                                    primary={meta.title}
                                                    secondary={materia[meta.key]}
                                                />
                                            </ListItem>
                                        })}
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        </div>;
                    })}
                </div>

            })}
        </Container>
    );
}

