import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import {ImagesResources} from '../Source/ImagesResources'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        height: '100%',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        padding: '50px 50px'
    },
}));

export default function Imagenes(props) {
const classes = useStyles();

    return (
        <div className={classes.root}>
            <GridList className={classes.gridList} cols={3}>
                {ImagesResources.map((tile) => (
                    <GridListTile cols={1}>
                        <img src={tile.url} alt={tile.alt}/>
                    </GridListTile>
                ))}
            </GridList>
        </div>
    );
}

