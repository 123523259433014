import React from "react";
import {Container, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ListItem from '@material-ui/core/ListItem';
import {MateriasDownloadable} from "../Source/MateriasDownloadable";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import VideocamIcon from '@material-ui/icons/Videocam';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import blueGrey from "@material-ui/core/colors/blueGrey";
import pink from "@material-ui/core/colors/pink";
import indigo from "@material-ui/core/colors/indigo";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '80%',
        padding: '50px 50px',
        color: blueGrey[800],
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: '500',
    },
    accordionHeader: {
        fontWeight: theme.typography.fontWeightMedium
    },
    titles: {
        backgroundImage: `url("https://sim4r4-public.s3.amazonaws.com/enfermeriauvt-source/Images/Books.jpg")`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '220px',
        borderRadius: 8,
        marginLeft: '10px',
        marginTop: '10px',
        color: 'white',
        padding: '25px 35px'
    }
}));

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

export default function Apoyo(props) {
    const classes = useStyles();
    return (
        <Container className={classes.root}>
            <div className={classes.titles}>
                <Typography variant="h2" gutterBottom>
                    Material de Apoyo
                </Typography>
                <Typography variant="h3" gutterBottom>
                    <span>
                        Plan de Estudios
                    </span>
                    <div>
                        Licenciatura en Enfermería
                    </div>
                </Typography>
            </div>
            {MateriasDownloadable.map(semestre => {
                return <div>
                    <Typography variant='h4' style={{padding: '30px 0'}}>
                        {semestre.semester}
                    </Typography>
                    {semestre.materias.map(materia => {
                        return <div>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        className={classes.accordionHeader}>{materia.nombre}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div>
                                        <Typography>
                                            <div>
                                                <List component="nav" aria-label="secondary mailbox folders">
                                                    {materia.recursos.map(resource => {
                                                        return <ListItemLink href={resource.url}>
                                                            <ListItemIcon>
                                                                {resource.tipo === 'VIDEO' ?
                                                                    <VideocamIcon style={{color: pink[400]}}/> :
                                                                    <ImportContactsIcon style={{color: indigo[500]}}/>}
                                                            </ListItemIcon>
                                                            <ListItemText primary={resource.nombre}/>
                                                        </ListItemLink>
                                                    })}
                                                </List>
                                            </div>
                                        </Typography>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>;
                    })}
                </div>
            })}
        </Container>
    );
}

