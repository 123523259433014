export const SimuladorResources = [
    {
        title: 'Simulador de Enfermería',
        alt: 'simuladores enfermeria universidad vasconcelos de tabasco',
        description: 'Simulación es la necesidad de experimentar, conocer , definir, anticiparse, sentir,notar,intentar ' +
            'resolver y sobre todo aprender causando el mayor numero de errores posibles en un campo de estudio limitado.',
        url: 'https://enfermeriaintensiva.jimdofree.com/simuladores/',
        img: 'https://sim4r4-public.s3.amazonaws.com/enfermeriauvt-source/Images/simulador-enfermeria.jpg',
    },
    {
        title: 'Simulador de Histología',
        alt: 'simuladores enfermeria universidad vasconcelos de tabasco',
        description: 'Este programa, desarrollado por el Departamento de Morfología y Biología Celular de la Universidad ' +
            'de Oviedo, recoge cientos de imágenes con información detallada sobre tejidos orgánicos.',
        url: 'https://atlas-interactivo-de-histologia.softonic.com/',
        img: 'https://sim4r4-public.s3.amazonaws.com/enfermeriauvt-source/Images/simulador-histiologia.jpg'
    },
    {
        title: 'Navegador de Huesos',
        alt: 'simuladores enfermeria universidad vasconcelos de tabasco',
        description: 'VHS es una plataforma  simple y eficiente que le permitirá mantener siempre disponible de una ' +
            'manera ordenada la información necesaria para la atención de sus pacientes ya sea en su Consultorio o en un ' +
            'Hospital, siempre en apego a la normatividad en materia de salud.',
        url: 'https://bonelab.softonic.com/',
        img: 'https://sim4r4-public.s3.amazonaws.com/enfermeriauvt-source/Images/simulador-huesos.jpg'

    },
    {
        title: 'Expediente Clínico Electrónico',
        alt: 'simuladores enfermeria universidad vasconcelos de tabasco',
        description: 'Con BoneLab puedes navegar por todos los huesos en tres dimensiones. Los modelos, de alta calidad, ' +
            'admiten rotaciones libres y zoom. Muchos grupos de huesos están coloreados y contienen etiquetas y anotaciones ' +
            'que pueden. Toda las funciones son accesibles con un clic derecho.',
        url: 'https://vhs.com.mx/',
        img: 'https://sim4r4-public.s3.amazonaws.com/enfermeriauvt-source/Images/simulador-electronico.jpg'
    },

]