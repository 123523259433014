export const MateriasSource = [
    {
        semestre: "Primer Semestre",
        materias: [
            {
                nombre: "Bases de Anatomía y Fisiología",
                ciclo: "1er Semestre",
                horasIndependientes: "16",
                horasDocente: "48",
                horasPracticas: "0",
                clave: "FB-343101",
                creditos: "4",
                seriacion: "FB-343108",
                objetivo: "Al finalizar el curso, el estudiante tendrá conocimientos habilidades y actitudes para identificar las estructuras morfológicas (órganos, estructuras, aparatos y sistemas del cuerpo humano) y conocerá los procesos fisiológicos a través de las actividades de aprendizaje y técnicas de enseñanza con bases científicas humanísticas e incluyentes."
            },
            {
                nombre: "Microbiología y Parasitología",
                ciclo: "1er Semestre",
                horasIndependientes: "16",
                horasDocente: "48",
                horasPracticas: "0",
                clave: "FB-343102",
                creditos: "4",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos habilidades y actitudes para identificar la importancia de la microbiología en el área de la salud, como una ciencia que estudia a los microorganismos y las patologías causales más comunes en nuestra región, con la finalidad de realizar acciones oportunas preventivas y curativas en la persona familia y comunidad con bases científicas humanísticas e incluyentes."
            },
            {
                nombre: "Salud Pública y Epidemiología",
                ciclo: "1er Semestre",
                horasIndependientes: "16",
                horasPracticas: "0",
                horasDocente: "48",
                clave: "FB-343103",
                creditos: "4",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos habilidades y actitudes para comprender la importancia de la salud pública y la epidemiologia en la enfermería, con la finalidad de que desarrollen acciones para la prevención de enfermedades e incidir favorablemente en los indicadores de Salud Pública de su región, con bases científicas humanísticas e incluyente."
            },
            {
                nombre: "Enfermería Comunitaria",
                ciclo: "1er Semestre",
                horasIndependientes: "16",
                horasDocente: "64",
                horasPracticas: "0",
                clave: "FB-343104",
                creditos: "5",
                seriacion: "FB-343111",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos, habilidades, y actitudes para identificar los conceptos básicos de salud comunitaria, la integración de los sistemas de salud y el rol del profesional de enfermería en la atención comunitaria y será capaz de realizar las intervenciones del primer nivel de atención a la persona, familia y comunidad utilizando la metodología del proceso enfermero con base científicas humanísticas e incluyentes."
            },
            {
                nombre: "Práctica de Enfermería Comunitaria",
                ciclo: "1er Semestre",
                horasIndependientes: "16",
                horasPracticas: "240",
                horasDocente: "0",
                clave: "FB-343105",
                creditos: "15",
                seriacion: "FB-343112",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos habilidades y actitudes para desarrollar un diagnóstico de salud dirigido a un área y población específica y realizar intervenciones de educación y prevención a la salud de la persona, familia y comunidad utilizando la metodología del proceso enfermero, con bases científicas, humanísticas e incluyentes."
            },
            {
                nombre: "Proceso Enfermero",
                ciclo: "1er Semestre",
                horasIndependientes: "16",
                horasDocente: "48",
                horasPracticas: "0",
                clave: "FB-343106",
                creditos: "4",
                seriacion: "FB-343113",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos, habilidades y actitudes para identificar y aplicar las etapas del Proceso de Enfermería a la persona, familia y comunidad utilizando la metodología y el pensamiento crítico que le permita valorar, diagnosticar, realizar intervenciones, ejecutarlas y evaluar sus resultados para mejorar la salud, con bases científicas humanísticas e incluyentes."
            },
            {
                nombre: "Comunicación y Educación para la Salud",
                ciclo: "1er Semestre",
                horasIndependientes: "16",
                horasDocente: "48",
                horasPracticas: "0",
                clave: "FB-343107",
                creditos: "4",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos, habilidades y actitudes para establecer un proceso de comunicación oral y escrita, que le permita comprender al individuo, familia y comunidad, para aplicar el proceso de enseñanza-aprendizaje en las actividades de promocieon a la salud, autocuidado, estilos de vida saludables y actividades docentes que se requiren en su desarrollo profesional con bases científicas, humanísticas e incluyentes."
            },
        ],
    },
    {
        semestre: "Segundo Semestre",
        materias: [
            {
                nombre: "Fisiopatología",
                ciclo: "2do Semestre",
                horasIndependientes: "16",
                horasDocente: "48",
                horasPracticas: "0",
                clave: "FB-343108",
                creditos: "4",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos, habilidades y actitudes para conocer las bases fisiopatológicas y su importancia en el área de la salud, identificar en las personas los síntomas, signos y procesos patológicos de las enfermedades comunes para prevenirlos y atenderlos de forma oportuna con bases científicas humanísticas e incluyentes."
            },
            {
                nombre: "Bases Elementales de Bioquímica",
                ciclo: "2do Semestre",
                horasIndependientes: "16",
                horasDocente: "48",
                horasPracticas: "0",
                clave: "FB-343109",
                creditos: "4",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos, habilidades y actitudes para conocer los conceptos fundamentales de la bioquímica, las principales estructuras moleculares y el metabolismo, podrá comprender los procesos bioquímicos involucrados en el funcionamiento del organismo humano, analizar y comprender los cambios que se susciten en él, con bases científicas humanísticas e incluyentes."
            },
            {
                nombre: "Farmacología en Enfermería",
                ciclo: "2do Semestre",
                horasIndependientes: "16",
                horasDocente: "48",
                horasPracticas: "0",
                clave: "FB-343110",
                creditos: "4",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos, habilidades y actitudes para identificar los conceptos elementales de farmacología y comprender el roll del profesional de enfermería en los procedimientos para el manejo y uso adecuado de los fármacos, protegiendo la integridad de las personas, familia y comunidad, con un enfoque humanístico e incluyente."
            },
            {
                nombre: "Enfermería Fundamental",
                ciclo: "2do Semestre",
                horasIndependientes: "16",
                horasDocente: "48",
                horasPracticas: "0",
                clave: "FB-343111",
                creditos: "4",
                seriacion: "FP-343118",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos, habilidades y actitudes del roll de enfermería y las funciones propias de la disciplina en el equipo inter y multidisciplinario, para aplicar los conocimientos teóricos y las principales técnicas de enfermería que le permitan proporcionar cuidados integrales, al individuo, familia y/o comunidad con bases científicas, humanísticas e incluyentes y con la metodología del proceso-enfermero."
            },
            {
                nombre: "Práctica Clínica de Enfermería Fundamental",
                ciclo: "2do Semestre",
                horasIndependientes: "16",
                horasDocente: "48",
                horasPracticas: "0",
                clave: "FB-343112",
                creditos: "4",
                seriacion: "FB-343119",
                objetivo: "Al finalizar el curso el estudiante habrá adquirido conocimientos, habilidades y actitudes para aplicar el proceso enfermero a la persona hospitalizada, realizar los procedimientos fundamentales de enfermería invasivos y no invasivos, apegados a las técnicas correctas que contribuyan al bienestar integral de la persona, con bases científicas humanísticas e incluyentes."
            },
            {
                nombre: "Modelos y Teorías de Enfermería",
                ciclo: "2do Semestre",
                horasIndependientes: "16",
                horasDocent: "48",
                horasPracticas: "0",
                clave: "FB-343113",
                creditos: "4",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos, habilidades y actitudes para analizar los diversos modelos y teorías que fundamentan el ejercicio profesional de la enfermería para que comprenda y aplique los conocimientos en el cuidado de las personas, familia y comunidad utilizando el proceso enfermero, con bases científicas humanísticas e incluyentes."
            },
            {
                nombre: "Ética, Bioética y Legislación en Enfermería",
                ciclo: "2do Semestre",
                horasIndependientes: "16",
                horasDocente: "48",
                horasPracticas: "0",
                clave: "FB-343114",
                creditos: "4",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos, habilidades y actitudes para ejecutar la práctica de la enfermería, proporcionando cuidados a la persona, familia y comunidad con bases éticas y bioéticas, aplicando los valores de la disciplina y respetando los derechos humanos y la legislación de la práctica, con bases científicas humanísticas e incluyentes."
            },
        ],
    },
    {
        semestre: "Tercer Semestre",
        materias: [
            {
                nombre: "Ginecología y Obstétrica",
                ciclo: "3er Semestre",
                horasIndependientes: "16",
                horasDocente: "48",
                clave: "FB-343115",
                horasPracticas: "0",
                creditos: "4",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos, habilidades y actitudes para ofrecer cuidados a la mujer en su etapa reproductiva y no reproductiva y contribuir a la salud de la mujer en la prevención y atención de los principales trastornos de salud en la mujer en sus diversas etapas, con bases científicas humanísticas e incluyentes."
            },
            {
                nombre: "Crecimiento y Desarrollo",
                ciclo: "3er Semestre",
                horasIndependientes: "16",
                horasDocente: "48",
                horasPracticas: "0",
                clave: "FB-343116",
                creditos: "4",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos, habilidades y actitudes para identificar las características del crecimiento y desarrollo humano desde el embrión hasta la adolescencia, para identificar los factores de riesgo y realizar un plan de intervenciones según las necesidades encontradas, utilizando el pensamiento crítico con bases científicas, éticas, humanísticas e incluyentes y la metodología del proceso enfermero."
            },
            {
                nombre: "Sexualidad y Educación Sexual",
                ciclo: "3er Semestre",
                horasIndependientes: "16",
                horasDocente: "48",
                horasPracticas: "0",
                clave: "FB-343117",
                creditos: "4",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos, habilidades y actitudes para identificar los elementos básicos de la sexualidad humana, género y las principales patologías sexuales desde un enfoque integral que le permita educar a la persona, familia y comunidad, en los temas de sexualidad de forma científica, objetiva, ética, humanística e incluyente."
            },
            {
                nombre: "Enfermería Materno-Perinatal",
                ciclo: "3er Semestre",
                horasIndependientes: "16",
                horasDocente: "64",
                horasPracticas: "0",
                clave: "FB-343118",
                creditos: "5",
                seriacion: "FP-343119",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos, habilidades y actitudes para identificar factores de riesgo en la etapa preconcepcional, en el embarazo, el parto y la atención del recién nacido, podrá realizar un plan de cuidados de enfermería a la mujer en estas etapas utilizando la metodología del proceso enfermero para identificar oportunamente los datos de alarma, otorgar los cuidados que favorezcan la salud materna y perinatal, con bases científicas, humanísticas e incluyentes."
            },
            {
                nombre: "Práctica Comunitaria y Clínia de Enfermería Perinatal",
                ciclo: "3er Semestre",
                horasIndependientes: "0",
                horasDocente: "0",
                horasPracticas: "16",
                clave: "FB-343112",
                creditos: "15",
                seriacion: "FB-343119",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos, habilidades y actitudes para identificar factores de riesgo en la mujer antes del embarazo, el embarazo, parto, y puerperio, realizará las valoraciones del recién nacido para identificar riesgos oportunos, podrá aplicar el proceso enfermero a la mujer y al recién nacido en la etapa preconcepcional, durante la gestación, el parto y el puerperio y actividades educativas para favorecer la salud materno-perinatal, con bases científicas humanísticas e incluyentes."
            },
            {
                nombre: "Filosofía y Enfermería",
                ciclo: "3er Semestre",
                horasIndependientes: "16",
                horasDocente: "32",
                horasPracticas: "0",
                clave: "FB-343120",
                creditos: "3",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos, habilidades y actitudes para comprender al ser humano, sus principios filosóficos y su dimensión social en la práctica de enfermería con bases científicas humanísticas e incluyentes."
            },
            {
                nombre: "Inglés I",
                ciclo: "3er Semestre",
                horasIndependientes: "16",
                horasDocente: "32",
                horasPracticas: "0",
                clave: "FB-343135",
                creditos: "3",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos, habilidades y actitudes para desarrollar competencias lingüísticas del idioma inglés y el interés por comunicarse en el idioma, al mismo tiempo que adquiere estrategias de compresión de lectura aplicadas a  textos con propósitos específicos del área de la salud como parte de su formación profesional."
            },
        ],
    },
    {
        semestre: "Cuarto Semestre",
        materias: [
            {
                nombre: "Enfermería en Medicina Crítica",
                ciclo: "4to Semestre",
                horasIndependientes: "16",
                horasDocente: "48",
                clave: "FB-343122",
                horasPracticas: "0",
                creditos: "4",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos, habilidades y actitudes para el cuidado integral de la persona adulta con patologías críticas en la unidad de cuidados intensivos, utilizando el proceso enfermero, para reducir las complicaciones, discapacidades o la muerte, con bases científicas humanísticas e incluyentes."
            },
            {
                nombre: "Nutrición y Dietoterapia",
                ciclo: "4to Semestre",
                horasIndependientes: "16",
                horasDocente: "48",
                horasPracticas: "0",
                clave: "FB-343123",
                creditos: "4",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos, habilidades y actitudes para identificar los conceptos básicos, factores y características de la nutrición adecuada y las necesidades nutricionales de la persona, familia y comunidad para proporcionarles educación de la nutrición adecuada y sus beneficios en las distintas edades del ser humano, con base científica humanística e incluyente."
            },
            {
                nombre: "Calidad y Seguridad del Paciente",
                ciclo: "4to Semestre",
                horasIndependientes: "16",
                horasDocente: "48",
                horasPracticas: "0",
                clave: "FB-343124",
                creditos: "4",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos habilidades y actitudes para comprender los conceptos, componentes, características, importancia y modelos de calidad y aplicar la calidad en los procesos de enfermería en los tres niveles de atención de las instituciones de salud para contribuir a la seguridad de los pacientes, familia y comunidad, con bases científicas humanísticas e incluyente."
            },
            {
                nombre: "Enfermería Quirúrgica",
                ciclo: "4to Semestre",
                horasIndependientes: "16",
                horasDocente: "64",
                horasPracticas: "0",
                clave: "FB-343125",
                creditos: "5",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos habilidades y actitudes para realizar las intervenciones de enfermería en pacientes en estado crítico y en los procedimientos quirúrgicos más frecuentes en las etapas del preoperatorio, transoperatorio y postoperatorio para prevenir patologías quirúrgicas y contribuir a la recuperación de las ya existentes, con bases científicas humanísticas e incluyentes."
            },
            {
                nombre: "Práctica Clínica de Enfermería Quirúrgica y Medicina Crítica",
                ciclo: "4to Semestre",
                horasIndependientes: "0",
                horasDocente: "0",
                horasPracticas: "240",
                clave: "FB-343126",
                creditos: "15",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante aplicará en los escenarios reales los conocimientos, habilidades y actitudes en los pacientes que requieren procedimientos quirúrgicos en las etapas del preoperatorio, transoperatorio y postoperatorio y al paciente en estado crítico utilizando la metodología del proceso enfermero para educar a la persona y familia, prevenir complicaciones, limitar los daños a la salud y lograr la recuperación, con bases científicas humanísticas e incluyentes."
            },
            {
                nombre: "Desarrollo Humano",
                ciclo: "4to Semestre",
                horasIndependientes: "16",
                horasDocente: "32",
                horasPracticas: "0",
                clave: "FB-343127",
                creditos: "3",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos, habilidades y actitudes para comprender al ser humano en su entorno mediante el diagnóstico de sus necesidades y potencialidades en las diferentes áreas de su vida y será capaz de diseñar estrategias para mejorar su autoconcepto y las conductas como el autocuidado, con bases científicas humanísticas e incluyentes."
            },
            {
                nombre: "Tecnologías de Información Computacional",
                ciclo: "4to Semestre",
                horasIndependientes: "16",
                horasDocente: "32",
                horasPracticas: "0",
                clave: "FB-343121",
                creditos: "3",
                seriacion: "FP-343135",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos, habilidades y actitudes para conocer los conceptos, antecedentes y funcionamiento de los sistemas de información y computación, desarrollará las habilidades para aplicar los comandos y funciones básicas de las principales plataformas operativas de las computadoras y la importancia del uso de la tecnología en el área de la salud."
            },
        ],
    },
    {
        semestre: "Quinto Semestre",
        materias: [
            {
                nombre: "Pediatría",
                ciclo: "5to Semestre",
                horasIndependientes: "16",
                horasDocente: "48",
                clave: "FB-343129",
                horasPracticas: "0",
                creditos: "4",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el estudiante habrá adquirido conocimientos, habilidades y actitudes para reconocer los factores de riesgo que afectan el desarrollo del niño y del adolescente y las principales patologías pediátricas para realizar las intervenciones pertinentes en los tres niveles de atención con bases científicas humanísticas e incluyentes."
            },
            {
                nombre: "Enfermería Basada en Evidencias",
                ciclo: "5to Semestre",
                horasIndependientes: "16",
                horasDocente: "48",
                horasPracticas: "0",
                clave: "FB-343130",
                creditos: "4",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido, conocimientos, habilidades y actitudes para fundamentar la práctica de la enfermería en la investigación científica con un enfoque sistemático, reflexivo e interpretativo utilizando los hallazgos de la investigación para mejorar la capacidad de resolución de problemas y la toma de decisiones en el cuidado de la persona, familia y comunidad con bases científicas humanísticas e incluyentes."
            },
            {
                nombre: "Psicología",
                ciclo: "5to Semestre",
                horasIndependientes: "16",
                horasDocente: "48",
                horasPracticas: "0",
                clave: "FB-343131",
                creditos: "4",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido, conocimientos, habilidades y actitudes para identificar los conceptos y fundamentos teóricos de la psicología y su relación con la enfermería, identificará los procesos mentales, las sensaciones, las percepciones y el comportamiento del ser humano, en relación con el medio ambiente físico y social que lo rodea para entender su actuar en el campo de la salud y colaborar en su salud integral, con bases científicas humanísticas e incluyentes."
            },
            {
                nombre: "Enfermería Pediátrica",
                ciclo: "5to Semestre",
                horasIndependientes: "16",
                horasDocente: "64",
                horasPracticas: "0",
                clave: "FB-343132",
                creditos: "5",
                seriacion: "FB-343132",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos, habilidades y actitudes para identificar los factores de riesgo que afectan el desarrollo del infante y adolescente normal, podrá realizar intervenciones educativas para prevenir patologías y acciones asistenciales al paciente pediátrico para mantener su salud integral utilizando la metodología del proceso enfermero, con bases científicas humanísticas e incluyentes."
            },
            {
                nombre: "Práctica Comunitaria y Clínica de Enfermería Pediátrica",
                ciclo: "5to Semestre",
                horasIndependientes: "0",
                horasDocente: "0",
                horasPracticas: "240",
                clave: "FB-343133",
                creditos: "15",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos, habilidades y actitudes para identificar los factores de riesgo que afectan el desarrollo del infante y adolescente normal, podrá realizar intervenciones educativas y asistenciales en los escenarios reales,  para prevenir patologías y proporcionar atención de enfermería al paciente pediátrico hospitalizado para mantener su salud integral utilizando la metodología del proceso enfermero, con bases científicas humanísticas e incluyentes."
            },
            {
                nombre: "Enfermería e Interculturalidad",
                ciclo: "5to Semestre",
                horasIndependientes: "16",
                horasDocente: "48",
                horasPracticas: "0",
                clave: "FB-343134",
                creditos: "4",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos, habilidades y actitudes para contextualizar la interculturalidad, analizará los modelos y teorías de la medicina intercultural, de la medicina convencional y las técnicas utilizadas en la medicina tradicional para su aplicación en las personas, familia y comunidad, con bases científicas humanísticas e incluyentes."
            },
            {
                nombre: "Inglés II",
                ciclo: "5to Semestre",
                horasIndependientes: "16",
                horasDocente: "32",
                horasPracticas: "0",
                clave: "FB-343142",
                creditos: "3",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos habilidades y actitudes para comunicarse con fluidez en el idioma inglés, presentándose y dando información general y sencilla sobre eventos cotidianos usando patrones simples estructurales, en la comprensión lectora, podrá identificar los elementos de un texto, la idea general y buscar información específica en los diversos textos."
            },
        ],
    },
    {
        semestre: "Sexto Semestre",
        materias: [
            {
                nombre: "Geriatría y Gerontología",
                ciclo: "6to Semestre",
                horasIndependientes: "16",
                horasDocente: "48",
                clave: "FB-343136",
                horasPracticas: "0",
                creditos: "4",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido, conocimientos, habilidades y actitudes para analizar y comprender los problemas y enfermedades de los adultos mayores en el entorno familiar y comunitario para establecer acciones de prevención y atención integral  con la metodología del proceso enfermero y con bases científicas humanísticas e incluyentes."
            },
            {
                nombre: "Enfermedades Crónico Degenerativas",
                ciclo: "6to Semestre",
                horasIndependientes: "16",
                horasDocente: "48",
                horasPracticas: "0",
                clave: "FI-343137",
                creditos: "4",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido, conocimientos, habilidades y actitudes para identificar los factores de riesgo, los mecanismos de prevención, detección y control de las enfermedades crónico-degenerativas y podrá realizar un plan de intervenciones con la metodología del proceso enfermero a la persona con estas patologías para su cuidado integral con bases científicas humanísticas e incluyentes."
            },
            {
                nombre: "Metodología de la Investigación I",
                ciclo: "6to Semestre",
                horasIndependientes: "16",
                horasDocente: "48",
                horasPracticas: "0",
                clave: "FI-343138",
                creditos: "4",
                seriacion: "FI-343145",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido, conocimientos, habilidades y actitudes para identificar los elementos básicos de la investigación, la importancia en la salud y elaborará un proyecto de investigación de un problema que tenga relación con la salud con la finalidad de generar en el un pensamiento sistémico, crítico y analítico."
            },
            {
                nombre: "Enfermería y el Adulto Mayor",
                ciclo: "6to Semestre",
                horasIndependientes: "16",
                horasDocente: "64",
                horasPracticas: "0",
                clave: "FB-343139",
                creditos: "5",
                seriacion: "FB-343140",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos, habilidades y actitudes para identificar el roll del cuidado en el adulto mayor, prevenir y atender patologías más frecuentes a través del proceso enfermero en los tres niveles de atención con bases científicas humanísticas e incluyentes."
            },
            {
                nombre: "Práctica Comunitaria y Clínica de Enfermería del Adulto Mayor",
                ciclo: "6to Semestre",
                horasIndependientes: "0",
                horasDocente: "0",
                horasPracticas: "240",
                clave: "FB-343140",
                creditos: "15",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido, conocimientos, habilidades y actitudes para aplicar los procedimientos y técnicas de enfermería para la valoración integral del adulto mayor y establecer un plan de cuidados para la prevención y atención de las patologías identificadas, utilizando la metodología del proceso enfermero con bases científicas humanísticas e incluyentes.  "
            },
            {
                nombre: "Cuidado del Niño y del Adolescente",
                ciclo: "6to Semestre",
                horasIndependientes: "16",
                horasDocente: "64",
                horasPracticas: "0",
                clave: "FB-343141",
                creditos: "5",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos, habilidades y actitudes para valorar las características del crecimiento y desarrollo del niño y el adolescente en sus diferentes etapas e identificará los cuidados específicos de enfermería para proporcionar cuidados integrales, con bases científicas humanísticas e incluyentes."
            },
            {
                nombre: "Tecnologías de Información y Computación II",
                ciclo: "6to Semestre",
                horasIndependientes: "16",
                horasDocente: "48",
                horasPracticas: "0",
                clave: "FB-343128",
                creditos: "3",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante adquirido conocimientos, habilidades y actitudes para conocer los conceptos, antecedentes y funcionamiento de los sistemas de información y computación y aplicar los comandos, funciones básicas de las principales plataformas operativas de las computadoras, con base científicas humanísticas e incluyente."
            },
        ],
    },
    {
        semestre: "Séptimo Semestre",
        materias: [
            {
                nombre: "Psiquiatría",
                ciclo: "7mo Semestre",
                horasIndependientes: "16",
                horasDocente: "48",
                clave: "FB-343143",
                horasPracticas: "0",
                creditos: "4",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos y habilidades para reconocer los elementos y procesos de enfermería en la valoración y atención del paciente psiquiátrico y la relación existente entre la familia y la comunidad e identificará las principales patologías que le afectan, con bases científicas humanísticas e incluyentes."
            },
            {
                nombre: "Bioestadística",
                ciclo: "7mo Semestre",
                horasIndependientes: "16",
                horasDocente: "32",
                horasPracticas: "0",
                clave: "FI-343144",
                creditos: "3",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos, habilidades y actitudes de los conceptos de estadística y bioestadística como una herramienta del Método Estadístico que aplicará en una investigación científica del área de la salud para la captura, sistematización, análisis de la información y toma de decisiones en beneficio de la población con base científicas humanísticas incluyentes."
            },
            {
                nombre: "Metodología de la Investigación II",
                ciclo: "7mo Semestre",
                horasIndependientes: "16",
                horasDocente: "48",
                horasPracticas: "0",
                clave: "FI-343145",
                creditos: "3",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido, conocimientos, habilidades y actitudes para valorar las características del crecimiento y desarrollo del niño y el adolescente en sus diferentes etapas e identificará los cuidados específicos de enfermería en estados de salud y de enfermedad, con base científicas humanísticas e incluyente."
            },
            {
                nombre: "Enfermería en Salud Mental",
                ciclo: "7mo Semestre",
                horasIndependientes: "16",
                horasDocente: "64",
                horasPracticas: "0",
                clave: "FB-343146",
                creditos: "5",
                seriacion: "FB-343147",
                objetivo: "Al finalizar el curso el estudiante habrá adquirido, conocimientos, habilidades y actitudes para identificar los conceptos de salud mental, los principales trastornos, así como las causas que pueden ocasionar una crisis y podrá realizar intervenciones de enfermería a la persona, familia y comunidad que favorezcan el bienestar y la calidad de vida utilizando la metodología del proceso enfermero con bases científicas humanísticas e incluyentes."
            },
            {
                nombre: "Práctica Comunitaria y Clínica de Enfermería en Salud Mental",
                ciclo: "7mo Semestre",
                horasIndependientes: "0",
                horasDocente: "0",
                horasPracticas: "240",
                clave: "FB-343147",
                creditos: "15",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido, conocimientos, habilidades y actitudes para evaluar e identificar oportunamente las alteraciones en salud mental en el individuo y la dinámica familiar, así como situaciones de crisis, realizando acciones para fomentar y promover la salud mental con la aplicación del proceso enfermero para restablecer la salud y fomentar los cambios de estilo de vida saludables, con base científicas humanísticas e incluyentes."
            },
            {
                nombre: "Enfermería en Medicina Física y Rehabilitación",
                ciclo: "7mo Semestre",
                horasIndependientes: "16",
                horasDocente: "48",
                horasPracticas: "0",
                clave: "FB-343148",
                creditos: "4",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido, conocimientos, habilidades y actitudes para identificar factores de riesgo y establecer medidas para prevenir la discapacidad, así mismo podrá realizar intervenciones de terapia física y rehabilitación a la persona con el fin de integrarlo a su vida productiva, utilizando la metodología del proceso enfermero  con bases científicas humanísticas e incluyentes."
            },
        ],
    },
        {
        semestre: "Octavo Semestre",
        materias: [
            {
                nombre: "Tanatología y Cuidados Paliativos",
                ciclo: "8vo Semestre",
                horasIndependientes: "16",
                horasDocente: "48",
                clave: "FB-343149",
                horasPracticas: "0",
                creditos: "4",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos, habilidades y actitudes para aplicar el proceso enfermero y realizar un plan de intervenciones al paciente en etapa terminal y a su familia, desarrollando la habilidad de reconocer aquellos factores que influyen en la aceptación de la etapa final de la vida y disminuir el proceso negativo de la enfermedad, con base científicas humanísticas e incluyente."
            },
            {
                nombre: "Cultura del Medio Ambiente y Sustentabilidad",
                ciclo: "8vo Semestre",
                horasIndependientes: "16",
                horasDocente: "32",
                horasPracticas: "0",
                clave: "FI-343150",
                creditos: "3",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos, habilidades y actitudes para Identificar y reflexionar en torno a los problemas ambientales con la finalidad de realizar acciones en salud que promuevan el cambio de comportamientos y mentalidad en la relación hombre-naturaleza- cultura- sociedad y así contribuir a la concientización y participación en la protección, cuidado del medio ambiente y sustentabilidad, con bases científicas humanísticas e incluyentes."
            },
            {
                nombre: "Seminario de Tesis",
                ciclo: "8vo Semestre",
                horasIndependientes: "16",
                horasDocente: "48",
                horasPracticas: "0",
                clave: "FI-343151",
                creditos: "4",
                seriacion: "Ninguna",
                objetivo: "Al finalizar este curso, el estudiante habrá adquirido conocimientos, habilidades y actitudes para elaborar, integrar y presentar su proyecto de investigación concluido con todos los apartados que se requieren y desarrollará las habilidades para la exposición y defensa de su trabajo de tesis, con bases científicas humanísticas e incluyentes."
            },
            {
                nombre: "Gerencia de Enfermería",
                ciclo: "8vo Semestre",
                horasIndependientes: "16",
                horasDocente: "64",
                horasPracticas: "0",
                clave: "FB-343152",
                creditos: "5",
                seriacion: "FB-343153",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos, habilidades y actitudes para identificar los conceptos fundamentales del proceso administrativo y los aplicará en la gerencia de los servicios de enfermería en las instituciones de salud en los tres niveles de atención con bases científicas humanísticas e incluyentes."
            },
            {
                nombre: "Práctica Comunitaria y Clínica de Gerencia en Enfermería",
                ciclo: "8vo Semestre",
                horasIndependientes: "0",
                horasDocente: "0",
                horasPracticas: "240",
                clave: "FB-343153",
                creditos: "15",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido, conocimientos, habilidades y actitudes para ejecutar las principales actividades del proceso administrativo, la adecuada planeación estratégica y administración de los recursos humanos y materiales en una organización de salud, con bases científicas humanísticas e incluyente."
            },
            {
                nombre: "Proyecto Innovadores de Salud",
                ciclo: "8vo Semestre",
                horasIndependientes: "16",
                horasDocente: "48",
                horasPracticas: "0",
                clave: "FB-343154",
                creditos: "4",
                seriacion: "Ninguna",
                objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos, habilidades y actitudes para diseñar un proyecto innovador a partir de los resultados del estudio del entorno regional, social, profesional y personal, que contribuya a satisfacer las necesidades sociales permitiéndole proyectarse en forma independiente en su práctica profesional, con bases científicas humanísticas e incluyentes."
            },
        ],
    }

];