import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {BrowserRouter as Router, Link} from "react-router-dom";
import {Drawer, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import ListIcon from '@material-ui/icons/List';
import FlightIcon from '@material-ui/icons/Flight';
import ImageIcon from '@material-ui/icons/Image';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {lightBlue} from '@material-ui/core/colors';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Routes from "./Routes";
import {AppContext} from "./libs/contextLib";
import {Auth} from "aws-amplify";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        width: 'inherit',
        backgroundColor: 'rgba(18, 45, 112, 1)',
        textColor: 'white',
    },
    link: {
        textDecoration: 'none',
        color: 'white'
    },
    leftBar: {
        paddingTop: '50px',
    },
    icons: {
        color: lightBlue[50]
    }
}));

function App() {
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [isAuthenticated, userHasAuthenticated] = useState(false);
    const history = useHistory();

    useEffect(() => {
        onLoad();
    }, []);

    async function handleLogout() {
        console.log('logging out');
        await Auth.signOut();
        userHasAuthenticated(false);
        history.push("/login");
    }

    async function onLoad() {
        try {
            let response = await Auth.currentSession();
            console.log(response, 'response');
            userHasAuthenticated(true);
        } catch (e) {
            console.log(e, 'error');
            if (e !== 'No current user') {
                alert('No current User');
            }
        }

        setIsAuthenticating(false);
    }


    const classes = useStyles();
    return (
        <Router>
            <div style={{display: 'flex'}}>
                <Drawer
                    style={{width: '230px'}}
                    variant="persistent"
                    anchor="left"
                    open={true}
                    classes={{paper: classes.drawerPaper}}
                >
                    <List className={classes.leftBar}>
                        <Link to="/" className={classes.link}>
                            <ListItem button>
                                <ListItemIcon>
                                    <HomeIcon className={classes.icons}/>
                                </ListItemIcon>
                                <ListItemText primary={"Inicio"}/>
                            </ListItem>
                        </Link>
                        <Link to={isAuthenticated ? '/materias' : '/'} className={classes.link}>
                            <ListItem button disabled={!isAuthenticated}>
                                <ListItemIcon>
                                    <ListIcon className={classes.icons}/>
                                </ListItemIcon>
                                <ListItemText primary={"Materias Semestrales"}/>
                            </ListItem>
                        </Link>
                        <Link to={isAuthenticated ? '/materias-cuatrimestre' : '/'} className={classes.link}>
                            <ListItem button disabled={!isAuthenticated}>
                                <ListItemIcon>
                                    <ListIcon className={classes.icons}/>
                                </ListItemIcon>
                                <ListItemText primary={"Materias Cuatrimestre"}/>
                            </ListItem>
                        </Link>
                        <Link to={isAuthenticated ? '/simuladores' : '/'} className={classes.link}>
                            <ListItem button disabled={!isAuthenticated}>
                                <ListItemIcon>
                                    <FlightIcon className={classes.icons}/>
                                </ListItemIcon>
                                <ListItemText primary={"Simuladores"}/>
                            </ListItem>
                        </Link>
                        <Link to={isAuthenticated ? '/imagenes' : '/'} className={classes.link}>
                            <ListItem button disabled={!isAuthenticated}>
                                <ListItemIcon>
                                    <ImageIcon className={classes.icons}/>
                                </ListItemIcon>
                                <ListItemText primary={"Imágenes"}/>
                            </ListItem>
                        </Link>
                        <Link to={isAuthenticated ? '/apoyo' : '/'} className={classes.link}>
                            <ListItem button disabled={!isAuthenticated}>
                                <ListItemIcon>
                                    <LocalLibraryIcon className={classes.icons}/>
                                </ListItemIcon>
                                <ListItemText primary={"Material Apoyo"}/>
                            </ListItem>
                        </Link>
                        <Link to={isAuthenticated ? '/biblioteca' : '/'} className={classes.link}>
                            <ListItem button disabled={!isAuthenticated}>
                                <ListItemIcon>
                                    <MenuBookIcon className={classes.icons}/>
                                </ListItemIcon>
                                <ListItemText primary={"Biblioteca Digital"}/>
                            </ListItem>
                        </Link>
                    </List>

                    <>
                        <Link to="/signup" className={classes.link} style={{position: 'absolute', bottom: '65px'}}>
                            <ListItem button hidden={!isAuthenticated}>
                                <ListItemIcon>
                                    <AccountCircleIcon className={classes.icons}/>
                                </ListItemIcon>
                                <ListItemText primary="Crear Cuenta"/>
                            </ListItem>
                        </Link>
                        {isAuthenticated ?
                            <Link to="/login" onClick={handleLogout}
                                className={classes.link} style={{position: 'absolute', bottom: '25px'}}>
                                <ListItem button>
                                    <ListItemIcon>
                                        <ExitToAppIcon className={classes.icons}/>
                                    </ListItemIcon>
                                    <ListItemText primary="Logout"/>
                                </ListItem>
                            </Link> :
                            <Link to="/login" className={classes.link}
                                  style={{position: 'absolute', bottom: '25px'}}>
                                <ListItem button onClick={handleLogout}>
                                    <ListItemIcon>
                                        <ExitToAppIcon className={classes.icons}/>
                                    </ListItemIcon>
                                    <ListItemText primary="Login"/>
                                </ListItem>
                            </Link>
                        }
                    </>

                </Drawer>
                <AppContext.Provider value={{isAuthenticated, userHasAuthenticated}}>
                    <Routes/>
                </AppContext.Provider>
            </div>
        </Router>
    );
}

export default App;
