import React from "react";
import {Container, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LinkIcon from '@material-ui/icons/Link';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import {BibliotecaSource} from '../Source/BibliotecaSource'
import pink from "@material-ui/core/colors/pink";
import indigo from "@material-ui/core/colors/indigo";

const useStyles = makeStyles((theme) => ({
    list: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    root: {
        padding: '50px 50px'
    },
    titles: {
        backgroundImage: `url("https://sim4r4-public.s3.amazonaws.com/enfermeriauvt-source/Images/BibliotecaDigital.jpg")`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '220px',
        borderRadius: 8,
        marginLeft: '10px',
        marginTop: '10px',
        color: 'white',
        padding: '25px 35px'
    }
}));

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

export default function Biblioteca(props) {
    const classes = useStyles();
    return (
        <Container className={classes.root}>
            <div className={classes.titles}>
                <Typography variant="h2" gutterBottom>
                    Biblioteca Digital
                </Typography>
                <Typography variant="h3" gutterBottom>
                    Revistas y recursos digitales
                </Typography>
            </div>
            <div className={classes.list}>
                <List component="nav" aria-label="secondary mailbox folders">
                    {BibliotecaSource.map(resource => {
                        return <ListItemLink href={resource.link}>
                            <ListItemIcon>
                                {resource.type === 'DIGITAL' ?
                                    <LinkIcon style={{color: pink[400]}}/> :
                                    <ImportContactsIcon style={{color: indigo[600]}}/>}
                            </ListItemIcon>
                            <ListItemText primary={resource.name} />
                        </ListItemLink>
                    })}
                </List>
            </div>
        </Container>
    );
}

